<template>
    <div class="user-view">


        <div >
     

            <div class="info-list">
                <div class="  flex list-item ">
                    <div>{{ $t('guojia') }}</div>
                    <div class="flex-end" @click="shuwPicker(0)"><span style="font-size: 12px; color: #8D8D8D;">{{ cdata.label }}</span><span
                            style="vertical-align: middle; margin-left: 10px;"><img src="../assets/user/right.png"
                                style="width: 6px; height: 10px;" alt=""></span></div>



                </div>

            </div>

            <div class="info-list">
                <div class="  flex list-item ">
                    <div>{{ $t('diqu') }}</div>
                    <div class="flex-end" @click="shuwPicker(1)"><span style="font-size: 12px; color: #8D8D8D;">{{ adata.label }}</span><span
                            style="vertical-align: middle; margin-left: 10px;"><img src="../assets/user/right.png"
                                style="width: 6px; height: 10px;" alt=""></span></div>


                </div>

            </div>

            <div class="info-list">
                <div class="login-btn" @click="doUserUpdate">

                        {{ $t("save") }}
                        </div>

            </div>


            <div class="footer">
                <div class="login-btn" @click="goback ">

                    {{ $t("goBack") }}
                </div>
            </div>

        </div>
         
 

             <Picker
            :visible.sync="visible"
            :data="areaList"
            :defaultIndex="defaultIndex"
          
            cancelText="取消"
            confirmText=" 确定"
            :maskClick="true"
           
            @cancel="cancel"
            @confirm="confirm"
        />



    </div>
</template>
  
<script>
import 'vue-min-picker/lib/picker.css';//导入样式(建议全局导入)
import pickers from "vue-min-picker";
let {Picker,PickerDate,PickerRegion} = pickers;
export default {
    name: "UserInfo",
    components:{
        Picker,
        PickerDate,
        PickerRegion,
    },

    data() {
      return{
        userInfo:{},
        editStatus:false , 
        nickname:'',
        areaList:[],
        visible:false,
        defaultIndex:0,
        cdata:"",
        adata:'',
        type:0

      }
    },
    created() {
    },
    mounted() {
        this.$nextTick(function() {
            this.getArea(0)

        })},
    methods: {
        goback() {
      this.$emit('showUser')
    },
        change(e){
            console.log('e: ', e);
        },
        cancel(){

        },
        confirm(e){
            if(e.length==0){
               e = [this.areaList[0][0] ]
            }
            console.log(e)
            if(this.type==0){
                this.cdata = e[0]
                this.getArea(e[0].value)
            }else{
                this.adata = e[0]
            }
          
        },

        getArea(pid=0){
            let _this = this
            let token = localStorage.getItem('token')
            let url = _this.$global.serverSrc+"/jeecg-boot/color/api/get/enCountry?parentid="+pid
            if(this.$i18n.locale == "zh-cn"){
                url = _this.$global.serverSrc+"/jeecg-boot/color/api/get/country?parentid="+pid
            }
            this.$ajax({
               method: "get",
               url:  url,
               header:{"X-Access-Token":token},
              
               }).then((res) =>{
                   console.log(res)
                   if(res.data.code==200){
                       this.areaList = [res.data.result]
                       this.defaultIndex = 0
                   }
               })
        },

        shuwPicker(type){
            this.visible = true
            this.type=type
            if(this.type==0){
                this.getArea(0)
            }else{
                if(!this.cdata){
                    this.areaList = []
                }
            }
        },
        doUserUpdate(){
           
           ///jeecg-boot/users/api/get/user
           if(!this.cdata){
            return
           }
           let token = localStorage.getItem('token')    
           let _this = this;
           let data = {country:this.cdata.value,area:this.adata.value}
           console.log(data)
           this.$ajax({
               method: "post",
               url:  _this.$global.serverSrc+"/jeecg-boot/users/api/update/user",
               header:{"X-Access-Token":token},
               data:data,
               }).then((res) =>{
                   console.log(res)
                   if(res.data.code==200){
                       _this.$layer.msg("编辑成功")
                       _this.userInfo = res.data.result

                       this.editStatus = false

                   }
               })

       },
       
      
   
    }

};
</script>
  
<style scoped>
.user-view {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    position: absolute;
    z-index: 10;
    overflow: scroll;
    pointer-events: all;
}

.page-header {
    padding-top: 30px;
    text-align: center;
    width: 100%;


}

.login-text {
    font-size: 12px;
    line-height: 17px;
    color: #8d8d8d;
    margin-top: 20px;

}

.flex {
    display: flex;
}

.tabs {
    width: 90%;
    margin-top: 20px;
    margin: 30px auto;
}

.tabs .tabs-item {
    width: 46%;
    margin: 0 2%;
    border: 1px solid;


    border-image-source: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
    border: 1px #fff solid;
    background-image: linear-gradient(180.21deg, rgba(255, 255, 255, 0.2) -9.27%, rgba(255, 255, 255, 0.6) 99.82%);
    border-radius: 14px;

    text-align: center;
    height: 64px;
    line-height: 64px;
    color: #3B3B3B;
    font-size: 14px;

}

.info-list {
    width: 88%;
    margin-left: 6%;
    margin-top: 20px;
    border-image-source: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
    border: 2px #fff solid;
    border-radius: 14px;
    background-image: linear-gradient(180.21deg, rgba(255, 255, 255, 0.2) -9.27%, rgba(255, 255, 255, 0.6) 99.82%);


}

.flex-end {
    margin-left: auto;
}

.list-item {
    margin: 20px;
}

.border-b {
    padding-bottom: 20px;
    border-bottom: 1px #fff solid;
}

.login-btn {
    width: 315px;
    border-radius: 6px;
    margin: auto;
    background-color: #335D9E;
    color: #ffffff;
    text-align: center;
    font-size: 14px;
    vertical-align: middle;
    line-height: 20px;
    padding: 10px;
}

.footer {
    width: 100%;

    position: fixed;
    bottom: 30px;
}
 

.info-list .input-view .form-input{
            border: none;
            background-color:  #F5F8FF;;
        
            height: 20px;
        }
   .info-list .input-view .form-input:focus {
            border: none;
            outline: none;
            box-shadow: none;
        }
        .info-list .input-view .form-input::placeholder {  
        color: #A1A1A1;
        }
</style>