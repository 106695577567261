<template>
  <div class="concat-view aboutus">
    <div :class="type!=1?'content2':'content3'">

      <img src="../assets/user/login-logo.png" width="173px" height="52px " style="margin:30px auto;">

      <!--div class=" contcat-text">
                    {{title}}
                </div-->

      <div class=" contcat-text" v-html="lg == 'en' ? content.enContent : content.content">

      </div>

      
    </div>

    <div class="footer-banquan1" v-show="type!=1">

       @optimal collection
    </div>

  

    <div class="aboutUs-footer">
      <div class="login-btn" @click="goback">

        {{ $t("goBack") }}
      </div>

    </div>


  </div>
</template>
<script>

export default {
  name: "AboutUs",
  
  data() {
    return {
      loadStatus: false,
      content: {},
      title: '',
      lg: '',
      type:''
    }
  },
  components: {

  },
  methods: {


  },
  mounted: function () { },
  created() {

  },
  methods: {
    goback() {
      this.$emit('showUser')
    },
    setlanguage(e) {
      this.lg = e
    },
    getInfo(type="") {
      
      this.type = type
      let _this = this
      let url = _this.$global.serverSrc + "/jeecg-boot/users/api/get/gywm"

      switch (type) {
        case 1:
          this.title = '用户协议'
          url = _this.$global.serverSrc + "/jeecg-boot/users/api/get/yhxy"   //用户协议
          break;
        case 2: {
          this.title = '隐私协议'
          url = _this.$global.serverSrc + "/jeecg-boot/users/api/get/ysxy"   //隐私协议
          break;
        }
        default: {
          this.title = '关于我们'
          url = _this.$global.serverSrc + "/jeecg-boot/users/api/get/gywm"  //关于我们
          break;
        }
      }

      this.$ajax({
        method: "post",
        url,
      }).then((res) => {
        console.log(res.data.result)
        if (res.data.code == 200) {


          _this.content = res.data.result

        }
      })

    }
  }

};
</script>
<style  scoped>
.concat-view {
  height: 100vh;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  z-index: 10;
  pointer-events: all;
}

.concat-view .content2 {
  position: relative;
  color: #3B3B3B;
  pointer-events: unset;
  border-radius: 14px;
  border: 1px #fff solid;
  background: linear-gradient(180.21deg, rgba(255, 255, 255, 0.2) -9.27%, rgba(255, 255, 255, 0.6) 99.82%), linear-gradient(181.7deg, #FFFFFF 1.39%, rgba(255, 255, 255, 0.4) 98.52%);
  width: 90vw;
  text-align: center;
  padding: 20px 0;
  margin: 30px auto;
  height:calc(100vh - 200px);
  overflow: auto;
}

.concat-view .content3 {
  position: relative;
  color: #3B3B3B;
  pointer-events: unset;
  border-radius: 14px;
  border: 1px #fff solid;
  background: linear-gradient(180.21deg, rgba(255, 255, 255, 0.2) -9.27%, rgba(255, 255, 255, 0.6) 99.82%), linear-gradient(181.7deg, #FFFFFF 1.39%, rgba(255, 255, 255, 0.4) 98.52%);
  width: 90vw;
  text-align: center;
  padding: 20px 0;
  margin: 30px auto;
  height:calc(100vh - 160px);
  overflow: auto;
}

.contcat-text {
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.30000001192092896px;
  padding: 0 20px;
  margin-top: 20px;
  text-align: left;

  
}

.footer-banquan1 {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;

  width: 100%;
}

.aboutus {
  height: 100vh;
  width: 100%;
 
  background-color: #eee;

}

.aboutus .aboutUs-footer {
  width: 100%;

  position: fixed;
  bottom: 30px;
}

.aboutus .aboutUs-footer .login-btn {
  width: 315px;
  border-radius: 6px;
  margin: auto;
  background-color: #335D9E;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  vertical-align: middle;
  line-height: 20px;
  padding: 10px;
}
</style>