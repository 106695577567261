<template>
    <div class="user-view">


        <div class="page-header">
            <img src="../assets/user/login-logo.png" width="173px" height="52px">


        </div>


        <div class="login-form">

            <div class="flex form-item">
                <div class="icon-view">
                    <img src="../assets/user/uname.png" width="16px" height="16px" alt="">
                </div>
                <div class="input-view">
                    <input type="text" v-model="account" class="form-input" :placeholder="$t('inputEmail')">
                </div>
            </div>

            <div class="flex form-item">
                <div class="icon-view">
                    <img src="../assets/user/pswd.png" width="16px" height="16px" alt="">
                </div>
                <div class="input-view">
                    <input type="password" v-model="password" class="form-input" :placeholder="$t('inputPsd')">
                </div>
            </div>
            <div style="margin-top: 20px; font-size: 12px; color:#838887 ;text-decoration: underline;" @click="toReg">

                {{$t('toReg')}}
            </div>
            <div class="  form-item-btn">
                <div class="login-btn" @click="doLogin">

                    {{$t('login')}}
                </div>
                <div style="width: 100%; text-align: center;margin-top: 30px;color: #a1a1a1; font-size: 14px;"
                    @click="showUser">

                    {{$t('unLogin')}}
                </div>

            </div>


        </div>


        <div class="footer  ">


            <div style="width: 100;text-align: center;">  {{$t('other')}}</div>
            <div class="flex">
                <div style="width: 50%;"></div>
                <div style="width: 50%;"></div>
            </div>

            <div class="flex" style="width: 40%; margin: 20px auto;">
                <div style="text-align: center; width: 100%;">
                    <img src="../assets/user/weixin.png" style="width: 32px;height: 32px;" alt="" @click="wxLogin()">
                </div>
                <!--div style="text-align: center;width: 50%;">
                    <img src="../assets/user/qq.png" style="width: 32px;height: 32px;" alt="">
                </div-->
            </div>
            <div class="flex">
                <div style="padding-top:8px; padding-right: 5px;"> <input type="radio" v-model="agree" @change="agreeChane"></div>
                <div> {{ $t('readXy') }}</div>
            </div>
        </div>



    </div>
</template>
  
<script>
//import func from 'vue-editor-bridge';
export default {
    name: "Login",
    data() {
        return {
            account: '',
            password: '',
            agree:false
        };
    },

    methods: {
        agreeChane(){
            this.agree = !this.agree
            console.log(this.agree)
        },
        toReg() {
            console.log('123')
            this.$emit("toReg")
        },
        showUser() {
            this.$emit('showUser', null)
        },
        doLogin() {
            let _this = this
            if (!this.validateEmail(this.account)) {
                this.$layer.msg("请正确输入邮箱地址")
                return
            }
            if (this.password.length < 6) {
                this.$layer.msg("请正确输入登录密码")
                return
            }

            if(!this.agree){
              
                this.$layer.msg("请阅读并同意用户协议")
                return
           
            }

            let data = {
                mobile: _this.account,
                password: _this.password
            }

            this.$ajax({
                method: "post",
                url: _this.$global.serverSrc + "/jeecg-boot/appletLogin/api/passwordLogin",
                data
            }).then((res) => {
                console.log(res)
                if (res.data.code == 200) {
                    _this.$layer.msg("登录成功")
                    localStorage.setItem('token', res.data.result.token)
                    setTimeout(() => {
                        _this.$emit('showUser', res.data.result)
                    }, 1500);


                } else {
                    _this.$layer.msg(res.data.message)
                }
            })


        },
        validateEmail(email) {
            var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(email);
        },
        wxLogin() {
            if (this.is_weixin()) {
                const code = this.getUrlParam("code"); // 截取路径中的code
                if (code == null || code === "") {
                    let url = "";
                    let userAgent = navigator.userAgent;
                   
                    url = window.location.href;
                     
                    window.location.href =
                        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx046189744cd11ff0&redirect_uri=" +
                        encodeURIComponent(url) +
                        "&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect";
                }  

                if (code != "" && code != null) {
                    this.wxCode = code;
                    console.log(code)
                    this.getOpenid(code)
                }
            }else{
                this.$layer.msg("请在微信客户端登录")
            }
        },
        // 判断是否是微信浏览器
        is_weixin() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
        getUrlParam(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
            var r = window.location.search.substr(1).match(reg);//匹配目标参数
            if (r != null) return unescape(r[2]); return null; //返回参数值
        },
        getOpenid(code){
            this.$ajax({
                method: "get",
                url: _this.$global.serverSrc + "/jeecg-boot/appletLogin/api/gzh/auth?code="+code,
               
            }).then((res) => {
                if (res.data.code == 200) {
                    _this.$layer.msg("登录成功")
                    localStorage.setItem('token', res.data.result.token)
                    this.$router.replace({
                        query: {
                            ...this.$route.query,
                            code: "",
                        },
                        });
                    setTimeout(() => {
                        _this.$emit('showUser', res.data.result)
                    }, 1500);


                } else {
                    _this.$layer.msg(res.data.message)
                }
            })
        }



    }

};
</script>
  
<style scoped>
.user-view {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    position: absolute;
    z-index: 10;
    overflow: scroll;
    pointer-events: all;
}

.page-header {
    padding-top: 70px;
    text-align: center;
    width: 100%;


}
.flex{
    display: flex;
}
.login-form {
    width: 315px;
    margin: 80px auto 30px;
}

.form-item-btn {
    margin-top: 30px;
    border-radius: 6px;
}

.login-form .form-item {
    background-color: #F5F8FF;
    padding: 14px;
    margin-top: 20px;
    border-radius: 6px;
}

.form-item .icon-view {
    width: 30px;
    text-align: center;
    padding-top: 3px;
}

.form-item .input-view {
    width: calc(100% - 30px);
}

.form-item .input-view .form-input {
    border: none;
    background-color: #F5F8FF;
    ;

    height: 20px;
}

.form-item .input-view .form-input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.form-item .input-view .form-input::placeholder {
    color: #A1A1A1;
}

.login-btn {
    width: 315px;
    border-radius: 6px;
    margin: auto;
    background-color: #335D9E;
    color: #ffffff;
    text-align: center;
    font-size: 14px;
    vertical-align: middle;
    line-height: 20px;
    padding: 12px 0;
}

.footer {
    font-size: 12px;
    letter-spacing: -0.3px;
    line-height: 16px;
    font-weight: 400;
    color: #838887;
   
    padding: 20px;
}</style>