const getters = {
    getInsideId(state) {
        return state.insideId
    },
    getInsideData(state) {
        return state.insideData
    },
    getOutsideId(state) {
        return state.outsideId
    },
    getOutsideData(state) {
        return state.outsideData
    },
    getPoint(state) {
        return state.point
    },
    getLight(state) {
        return state.light
    },
    getProduct(state) {
        return state.product
    },
    getScene(state) {
        return state.scene
    },
    getIcon(state) {
        return state.icon
    },
    getFromIcon(state) {
        return state.fromIcon
    },
    getInsideMaterial(state) {
        return state.insideMaterial
    },
    getOutSideMaterial(state) {
        return state.outsideMaterial
    },
    getLanguageType(state) {
        return state.languageType
    },
    getCurrentSide(state) {
        return state.currentSide
    },
 
    getGuide(state) {
        return state.guide
    },
    getUserinfo(state) {
        return state.userinfo
    }


}
export default getters