<template>
  <!-- <div class="v3dApp_container"> -->
  <div class="v3dApp">

    <transition name="fade">
      <Version :ShowVersion="ShowVersion" :Countdown="Countdown" :OnceCountDown="OnceCountDown"
        :languageType="languageType" @closeVersion="closeVersion" v-show="ShowVersion" />
    </transition>
    <div class="showMaskout" id="showMask"></div>
    <div :id="containerId"></div>
    <Preloader></Preloader>
    <Pageview @doMemberForm="doMemberForm" v-show="PageStatus"></Pageview>
    <Member :memberFormtype="memberFormtype" v-show="memberFormtype != '' && magnifierStatus == false"
      @doMemberForm="doMemberForm" @showPageView="showPageView"></Member>
    <Shares v-show="sharestatus && loadingFinish" :top_text1='top_text1' :top_text2='top_text2'
      :dbclickStatus="getUrlKey('dbclick')" ref="sharesCom" @closeShare="closeShare" :inID="insideData[insideColor_index].dataId" :outID="outsideData[outsideColor_index].dataId"></Shares>

    <!--Loading
      v-show="loadingStatus "
      @closeLoading="closeLoading"
    ></Loading-->

    <Copy v-show="copyStatus && loadingFinish" ref="copyCom" @closeShare="closeCopy" :shareUrl='shareUrl'
      :shareCopyText='shareCopyText' :copySuccessText='copySuccessText'></Copy>


    <!-- 流行色 -->
    <Hot v-if="ShowHot" @changeShowHot="changeShowHot" @changeColor="changeColor"></Hot>

    <confirm ref="myConfirm"></confirm>
    <div class="ExitMagnifier">
      <img src="../assets/options/exit_fangdajing.png" v-show="magnifierStatus" id="ExitMagnifier" alt=""
        @click="exitMagnifier" draggable="false" />
    </div>

    <div class="content1" :style="{
      filter: ShowVersion ? 'blur(2px)' : '',
    }">
      <div class="header" v-if="loadingFinish">
        <div class="logo">
          <img draggable="false" src="../assets/logo/logo-1.png" alt= />
        </div>
        <div class="header-text">
          <div class="colorContent" ref="imageDom"
            v-show="guide === 'icon-0' || guide === 'icon-04' || guide === 'icon-5'">
            <div class="title" id="inside_text" v-show="inside_text_show &&
              this.outsideDbStatus !== 1 &&
              this.outsidePointDbStatus !== 1
              ">
              <h4>
                {{ top_text1[0] }}
                <span class="color_text">-{{ top_text1[1] }}</span>
              </h4>
              <p class="color_text">{{ top_text1[2] }} {{ top_text1[3] }}</p>
            </div>
            <div class="title" id="outside_text" v-show="(outside_text_show || guide === 'icon-0') &&
              this.insideDbStatus !== 1 &&
              this.insidePointDbStatus !== 1
              ">
              <h4>
                {{ top_text2[0] }}
                <span class="color_text">-{{ top_text2[1] }}</span>
              </h4>
              <p class="color_text">
                {{ top_text2[2]
                }}<span v-show="guide == 'icon-1'">{{ LightText[currentOutsidePoint] }}
                </span>
                {{ top_text2[3] }}
              </p>
            </div>
          </div>

          <div class="otherContent" ref="otherContent" v-show="guide === 'icon-2'">
            <!--img :src="require(`../assets/morelogo/title-${otherImageId}.png`)" alt="" draggable="false" /-->
            <img :src="otherTypeImg" alt="" draggable="false">
          </div>

          <div class="tools" v-if="!magnifierStatus">
            <div class="tool" @click="toolClick">
              <img draggable="false" class="tool-img" src="../assets/tools/shezhi.png" />
            </div>

            <div v-show="tool_show">
              <div class="tool-line"></div>
              <!-- <div class="tool tool-padding" @click="doMemberForm('login')">
                <img class="tool-img" src="../assets/tools/tool_login.png" />
                <div class="tool-text">登录</div>
              </div> -->
              <div style="width:50px;height:50px;position:absolute;opacity:0.3;background-color: black;"
                v-show="loadingStatus == 'share'"></div>
              <div class="tool tool-padding" id="SendImg" @click="doShare()">
                <img draggable="false" class="tool-img" src="../assets/tools/share.png" />
                <div class="tool-text">{{ share }}</div>
              </div>
              <div style="width:50px;height:50px;position:absolute;;opacity:0.3;background-color: black;"
                v-show="loadingStatus == 'copy'"></div>

              <div class="tool tool-padding" id="SendImg" @click="doCopy()">
                <img draggable="false" class="tool-img" src="../assets/tools/copy.png" />
                <div class="tool-text">{{ copy }}</div>
              </div>

              <div class="tool" style="padding-bottom: 10px" @click="setLanguage('en')" v-show="languageType == 'cn'">
                <!-- <img class="tool-img" src="../assets/tools/english.png" /> -->
                <div class="tool-text">English</div>
              </div>
              <div class="tool" style="padding-bottom: 10px" @click="setLanguage('cn')" v-show="languageType == 'en'">
                <!-- <img class="tool-img" src="../assets/tools/english.png" /> -->
                <div class="tool-text">中文</div>
              </div>

              <div class="tool" @click="
                tool_show = false;
              OnceCountDown = false;
              ShowVersion = true;
              ">
                <img class="concat1" src="../assets/tools/version.png" alt="" />
              </div>
            </div>
          </div>


          <div class="tools-contact" v-show="!tool_show && !magnifierStatus">
            <div class="tool" @click="
              showConcat = true;
            tool_show = false;
            ">
              <img class="concat" src="../assets/tools/concat.png" alt="" />
            </div>
          </div>
        </div>
      </div>



      <Colorselect4 ref="colorselectChild4" v-show="guide === 'icon-04' && magnifierStatus == false && loadingFinish"
        v-if="loadingFinish && insideData && outsideData" @sendImg="sendImg" @showInsideInfo="showInsideInfo"
        @showOutsideInfo="showOutsideInfo" :insideColor="insideData" :outsideColor="outsideData" @insideDb="insideDb"
        @outsideDb="outsideDb" :languageType="languageType" :insideDbStatus="insideDbStatus"
        :outsidecolorselector_index="outsideColor_index" :insidecolorselector_index="insideColor_index"
        @changeColorIndex="changeColorIndex" :currentInside="currentInside" :currentOutside="currentOutside"
        :currentProduct="currentProduct" :loadingFinish="loadingFinish" @changeShowHot="changeShowHot"
        @tabClick="tabClick" @addLishi="addLishi" @getMoreData="getMoreData"></Colorselect4>




      <Colorselect ref="colorselectChild" v-show="guide === 'icon-0' && magnifierStatus == false && loadingFinish"
        v-if="loadingFinish && insideData && outsideData" @sendImg="sendImg" @showInsideInfo="showInsideInfo"
        @showOutsideInfo="showOutsideInfo" :insideColor="insideData" :outsideColor="outsideData" @insideDb="insideDb"
        @outsideDb="outsideDb" :languageType="languageType" :insideDbStatus="insideDbStatus"
        :outsidecolorselector_index="outsideColor_index" :insidecolorselector_index="insideColor_index"
        @changeColorIndex="changeColorIndex" :currentInside="currentInside" :currentOutside="currentOutside"
        :currentProduct="currentProduct" :loadingFinish="loadingFinish" @changeShowHot="changeShowHot"
        @tabClick="tabClick" @searchEmpty="searchEmpty" @searchSuccess="searchSuccess" @addLishi="addLishi"
        @getMoreData="getMoreData" @searchFuxian="searchFuxian"></Colorselect>



      <!-- 内外涂遮罩 -->
      <div class="insideMask" v-show="outsideDbStatus == 1 && (guide === 'icon-0' || guide === 'icon-04')"
        @click="showtips('inside')"></div>
      <div class="outsideMask" v-show="insideDbStatus == 1 && (guide === 'icon-0' || guide === 'icon-04')"
        @click="showtips('outside')"></div>

      <Pointselect ref="poinstSelect" v-if="loadingFinish && currentInside && currentOutside"
        v-show="guide === 'icon-5' && magnifierStatus == false && loadingFinish" @changeTextShow="changeTextShow"
        @getManifier="getManifier" @sendImg="sendImg" :currentInside="currentInside" :currentOutside="currentOutside"
        :insidePointDbStatus="insidePointDbStatus" :outsidePointDbStatus="outsidePointDbStatus"
        @changePointDb="changePointDb" :currentInsidePoint="currentInsidePoint" :currentOutsidePoint="currentOutsidePoint"
        @changePoint="changePoint" :inAnimation="inAnimation" @changeAnime="changeAnime"
        @changeSprinkleColor="changeSprinkleColor" :currentColor1="currentColor1" :currentColor2="currentColor2"
        @tabClick="tabClick">
      </Pointselect>

      <Production :languageType="languageType" v-if="guide === 'icon-1' && magnifierStatus == false && loadingFinish"
        ref="production" :currentProduct="currentProduct" @changeCurrentProduct="changeCurrentProduct"></Production>

      <Places v-if="guide === 'icon-6' && magnifierStatus == false && loadingFinish" :collectList="collectList"
        ref="childplaces" :insideData="insideData" :outsideData="outsideData" @deleCollect="deleCollect"></Places>




      <Othersop v-if="guide === 'icon-2' && magnifierStatus == false && loadingFinish" :needLoad="needLoad"
        @showotherInfo="showotherInfo" :currentProduct="currentProduct" :currentInside="currentInside"
        :currentOutside="currentOutside" ref="Othersop"></Othersop>
      <div class="footer" v-show="magnifierStatus == false && loadingFinish">
        <div class="tabbar">
          <!-- <Pointselect></Pointselect> -->
          <div class="tabbar-item" v-for="(item, index) in 4" :key="'icon-' + index" :id="'icon-' + index"
            @click.stop="tabClick('icon-' + index)" :style="{
              backgroundColor: tabbar_index == 'icon-' + index ? '#dddde9' : '',
            }">
            <img draggable="false" class="tab-img" :src="require(`../assets/tabbar/tab${index + 1}.png`)" alt= />
          </div>
        </div>
      </div>
    </div>
    <!-- 收藏动画 -->
    <div class="collectAnime" ref="collectAnime">
      <img draggable="false" class="collectImg" :src="collectImg" alt="" />
    </div>
    <!--  -->
    <!-- 提示动画 -->
    <transition name="fade">
      <div class="tipMsg" v-show="tipMsg">
        <p>收藏数量最多允许添加20个产品!</p>
        <p>注：如果想要继续收藏必须把之前收藏的删掉一些</p>
      </div>
    </transition>
    <!-- 联系我们 -->
    <Concat v-if="showConcat" @concatMaskClose="concatMaskClose"></Concat>


    <!---我的--->
    <User v-show="guide === 'icon-3' && magnifierStatus == false && loadingFinish" ref="users" @toLogin="toLogin"
      @showUser="showUser" @showInfo="showInfo" @showShoucang="showShoucang" @showLishi="showLishi"
      @showAboutUs="showAboutUs" :languageType="languageType" @showBindPhone="showBindPhone" @editArea="editArea"
      @showBindEmail="showBindEmail"></User>
    <!-- 登录页面 -->
    <Login v-if="showLogin" @loginSuccess="loginSuccess" @toReg="toReg" @showUser="showUser" @noLogin="noLogin"></Login>

    <!-- 注册页面 -->
    <Reg v-if="showReg" @regSuccess="regSuccess" @toLogin="toLogin"></Reg>

    <!-- 个人信息 -->
    <UserInfo v-show="showUserInfo" ref="userInfos" @showUser="showUser"></UserInfo>
    <!-- 绑定手机号码 -->
    <bindPhone v-show="showBindPhoneStatus" @showUser="showUser"></bindPhone>

    <!-- 绑定邮箱 -->
    <bindEmail v-show="showBindEmailStatus" @showUser="showUser"></bindEmail>

    <!-- 用户所在地区 -->
    <UserArea v-show="showAreaStatus" @showUser="showUser" ref="userArea"></UserArea>

    <!--收藏列表-->
    <Shoucang v-show="shoucangStatus" ref="shoucang" @showUser="showUser" @fuxian="scFuxian"></Shoucang>

    <!--历史记录列表-->
    <Lishi ref="lishi" v-show="lishiStatus" @showUser="showUser" @fuxian="lsFuxian"></Lishi>

    <!---关于我们--->
    <AboutUs v-show="aboutusStatus" ref="aboutus" @showUser="showUser"></AboutUs>



    <!--搜索消息-->
    <Searchmsg v-if="showSearch" @closeSearchMsg="closeSearchMsg" @searchyingyong="searchyingyong"
      :showSearchType="showSearchType" :successMsg="successMsg" :colors="colorLikes"></Searchmsg>
    <div class="right-icons" v-if="loadingFinish">
      <div><img src="../assets/gth.png" @click="showSearchmsg"
          v-show="guide == 'icon-5' || guide == 'icon-04' || guide == 'icon-0'" style="width: 16px;height: 16px; " alt="">
      </div>
      <div style="margin-top: 10px;" v-if="guide == 'icon-5' || guide == 'icon-04'" @click="tabClick('icon-0')"><img
          src="../assets/sousuo.png" style="width: 16px;height: 16px;" alt=""></div>
    </div>

  </div>

  <!-- </div> -->
</template>

<script>
// 截图
import html2canvas from "html2canvas";
//
import * as v3dAppAPI from "../Ledmodule/model.js";
import Preloader from "./Preloaderss.vue";
import Colorselect from "./Colorselect3.vue";
import Colorselect4 from "./Colorselect4.vue";
import Pointselect from "./Pointselect.vue";
import Production from "./Productions.vue";
import Places from "./Places.vue";
import Othersop from "./Othersop.vue";
import Member from "./Member.vue";
import Confirm from "./Confirm.vue";
import Pageview from "./Pageview.vue";
import User from "./User.vue";
import Shares from "./Shares.vue";
import Copy from "./Copy.vue";
import Concat from "./Concat.vue";
import Loading from "./Loading.vue";
import Login from "./Login.vue";
import Reg from "./Reg.vue";
import UserInfo from "./userinfo.vue";
import Shoucang from "./shoucang.vue";
import Lishi from "./Lishi.vue";
import AboutUs from "./AboutUs.vue";
import bindPhone from "./bindPhone.vue";
import bindEmail from "./bindEmail.vue";
import UserArea from "./UserArea.vue";
import Version from "../components/Version.vue";
import Searchmsg from "../components/Searchmsg.vue";
import Hot from "../components/Hot.vue";
import {
  SetColor,
  setPoint,
  setLight,
  LoadProduct,

} from "../Ledmodule/model.js";
const Base64 = require('js-base64').Base64
export default {
  name: "V3DApp",
  components: {
    Preloader,
    Colorselect,
    Pointselect,
    Production,
    Places,
    Othersop,
    Member,
    Confirm,
    Pageview,
    Shares,
    Concat,
    Version,
    Hot,
    Copy,
    Loading,
    Searchmsg,
    User,
    Colorselect4,
    Login,
    Reg,
    UserInfo,
    Shoucang,
    Lishi,
    AboutUs,
    bindPhone,
    UserArea,
    bindEmail
  },
  data() {
    return {
      containerId: v3dAppAPI.CONTAINER_ID,
      // 显示版本弹窗
      ShowVersion: false,
      searchType1: 1,
      Countdown: 10,
      OnceCountDown: true,
      // 热门弹窗
      ShowHot: false,
      share: "Share",
      copy: "Copy",
      shareUrl: '',
      shareCopyText: ' Long-press the link and you will copy it',
      copySuccessText: 'Copy succeeded',
      tool_show: false,
      guide: "icon-" + parseInt(this.getUrlKey("guide")),
      tabbar_index: "icon-" + parseInt(this.getUrlKey("guide")),
      dipan_show: false,
      top_text1: ["", "", "", ""],
      top_text2: ["", "", "", ""],
      otherImageId: "5",
      tabs: [
        { id: 0, src: "../assets/tabbar/tab1.png" },
        { id: 1, src: "../assets/tabbar/tab2.png" },
        { id: 2, src: "../assets/tabbar/tab3.png" },
        { id: 3, src: "../assets/tabbar/tab4.png" },
        { id: 4, src: "../assets/tabbar/tab5.png" },
      ],
      magnifierStatus: false,
      height: 0,
      width: 0,
      top: 0,
      left: 0,
      memberFormtype: "",
      PageStatus: false,
      sharestatus: false,
      loadingStatus: false,
      copyStatus: false,
      languageType: "en",
      insideColor: "",
      outsideColor: "",
      insideData: JSON.parse(localStorage.getItem('insideData')),
      outsideData: JSON.parse(localStorage.getItem('outsideData')),
      inside_text_show: true,
      outside_text_show: true,
      // 新增的收藏数据
      collectList: [
        {
          SimLight: "",
          SimPoint: "",
          dbclick: "none",
          insideColor: 275,
          outsideColor: 2957,
          product: "Product_0",
          src: require("../assets/collect/collect1.png"),
          color1: "",
          color2: "",
        },
        {
          SimLight: "",
          SimPoint: "",
          dbclick: "none",
          insideColor: 0,
          outsideColor: 218,
          product: "Product_0",
          src: require("../assets/collect/collect2.png"),
          color1: "",
          color2: "",
        },
      ],
      collectImg: null,
      // 内外涂双击状态
      insideDbStatus: 0,
      outsideDbStatus: 0,
      // 拟合内外涂双击状态
      insidePointDbStatus: 0,
      outsidePointDbStatus: 0,
      // 当前拟合选中
      currentInsidePoint: -1,
      currentOutsidePoint: -1,
      currentInsidePoint2: -1,
      currentOutsidePoint2: -1,
      // 内涂拟合选中的颜色
      currentColor1: "#ffffff",
      currentColor2: "#000000",
      // 闪光文案
      LightText: ["A", "B", "C"],
      // 内外涂选中序号
      insideColor_index: parseInt(this.getUrlKey("inside")),
      outsideColor_index: parseInt(this.getUrlKey("outside")),
      // 当前内外涂颜色数据,当前产品模型编号
      currentProduct: this.getUrlKey("product"),
      currentInside: {},
      currentOutside: {},
      defaultInside: this.getUrlKey("inside"),
      defaultOutside: this.getUrlKey("outside"),
      needLoad: false,
      // 分享的生命周期
      // shareKey: 0,
      timer: null,
      loadingFinish: false,
      tipMsg: false,
      tipTimer: null,
      // 动画
      inAnimation: true,
      // 联系我们
      showConcat: false,
      dbclickStatus: 0,
      //搜索消息
      showSearch: false,
      showSearchType: 'msg',
      successMsg: '',
      searchData: {},
      colorLikes: [],
      showLogin: false,
      showReg: false,
      showUserInfo: false,
      userInfoData: null,
      shoucangStatus: false,
      lishiStatus: false,
      aboutusStatus: false,
      inPageNo: 1,
      outPageNo: 1,
      showBindPhoneStatus: false,
      showBindEmailStatus: false,
      showAreaStatus: false,
      otherTypeImg: '',
      baseUrl: this.$global.serverSrc,
      dbStatus: false

    };
  },
  app: null,
  mounted: function () {
    let _this = this;
    this.checkIcon();
    let insideData;
    let outsideData;
    // 判断是否内外涂同色
    if (this.insideDbStatus == 1 || this.insidePointDbStatus == 1) {
      outsideData = insideData = this.insideData[this.insideColor_index];
    } else if (this.outsideDbStatus == 1 || this.outsidePointDbStatus == 1) {
      insideData = outsideData = this.outsideData[this.outsideColor_index];
    } else {
      insideData = this.insideData[this.insideColor_index];
      outsideData = this.outsideData[this.outsideColor_index];
    }
    // 判断是否有拟合效果
    let insidePoint;
    let outsidePoint;
    if (this.guide === "icon-1") {
      if (this.insidePointDbStatus == 1) {
        // 双击内涂的情况下
        outsidePoint = insidePoint = "point_" + this.currentInsidePoint;
      } else if (this.outsidePointDbStatus == 1) {
        // 双击外涂的情况下
        insidePoint = outsidePoint = "light_" + this.currentOutsidePoint;
      } else {
        insidePoint = "point_" + this.currentInsidePoint;
        outsidePoint = "light_" + this.currentOutsidePoint;
      }
    }
    if (this.guide == 'icon-3') {
      this.$refs.users.setlanguage(this.languageType)
      this.$refs.users.getUserInfo()
    }

    v3dAppAPI
      .createApp(
        this.currentProduct,
        insideData,
        outsideData,
        insidePoint,
        outsidePoint
      )
      .then((app) => {
        this.$options.app = app;
      });

    v3d._customEvents = new v3d.EventDispatcher();
    v3d._customEvents.addEventListener("onload", function (event) {
      // console.log("加载完毕");
      _this.loadingFinish = true;
      let vt = localStorage.getItem('vt') || 0
      let nt = Date.now();
      if (nt - vt > 3600000) {
        localStorage.setItem('vt', nt);
        _this.ShowVersion = true
        // 开始倒计时
        let timer = setInterval(function () {
          _this.Countdown -= 1;

          if (_this.Countdown <= 0 || !_this.ShowVersion) {
            clearInterval(timer);
            _this.ShowVersion = false;
          }
        }, 1000);
      }




      // var ua = navigator.userAgent.toLowerCase();
      // var isWXWork = ua.match(/wxwork/i) == "wxwork";
      // var isWeixin =
      //   !isWXWork && ua.match(/micromessenger/i) == "micromessenger";
      // var isMobile = false;
      // var isDesktop = false;
      // if (
      //   navigator.userAgent.match(
      //     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i
      //   )
      // ) {
      //   isMobile = true;
      // } else {
      //   isDesktop = true;
      // }
      // 如果是在微信
      // if (isWeixin) {
      //   window.location.href = "weixin://dl/business/?t=87JujeW4NYk";
      // }
    });
  },
  created() {
    // 初始化
    this.changeCurrentColor("inside", this.insideColor_index);
    this.changeCurrentColor("outside", this.outsideColor_index);
    // 双击效果
    let dbclickStatus = this.getUrlKey("dbclick");
    this.dbclickStatus = dbclickStatus
    if (this.guide === "icon-0" && dbclickStatus != 0) {
      this.insideDbStatus = dbclickStatus == 1 ? 1 : 0;
      this.outsideDbStatus = dbclickStatus == 2 ? 1 : 0;
    } else if (this.guide === "icon-1" && dbclickStatus != 0) {
      this.insidePointDbStatus = dbclickStatus == 1 ? 1 : 0;
      this.outsidePointDbStatus = dbclickStatus == 2 ? 1 : 0;
    }
    // 拟合效果
    let insidePoint = this.getUrlKey("SimPoint");
    let outsidePoint = this.getUrlKey("SimLight");
    this.currentInsidePoint = insidePoint.split("_")[1];
    this.currentOutsidePoint = outsidePoint.split("_")[1];


  },
  methods: {
    // 改变内外涂颜色
    changeColor(index1, index2) {
      console.log(index1, index2)
      this.$refs.colorselectChild.changeColor(index1, index2);
    },
    searchTypeChange(e) {
      console.log("456")
      this.searchType1 = e
    },
    // 改变当前产品模型
    changeCurrentProduct(index) {
      let insideData = JSON.parse(localStorage.getItem('baseInsideData'))
      let outsideData = JSON.parse(localStorage.getItem('baseOutsideData'))
      let _this = this;
      this.currentProduct = index;
      this.$router.replace({ query: { ...this.$route.query, product: index } });
      LoadProduct(
        this.currentProduct,
        this.insideData[this.defaultInside],
        this.outsideData[this.defaultOutside],
        "",
        "",
        function () {
          SetColor(
            insideData,
            "inside",
            "click",
            "",
            _this.currentProduct
          );
          SetColor(
            outsideData,
            "outside",
            "click",
            "",
            _this.currentProduct
          );

        }
      );
    },
    // 改变拟合选中
    changePoint(type, index) {
      clearInterval(this.timer);
      this.timer = setTimeout(() => {
        this.changePointFn(type, index);
      }, 300);
    },
    changePointFn(type, index, recover) {
      if (type === "inside") {
        if (
          this.$refs.poinstSelect.showColorList &&
          this.currentInsidePoint === index
        ) {
          // 如果已经出现了颜色列表再次点击取消选中
          this.$refs.poinstSelect.needshowColorList(false);
          this.changePointFn("inside", -2);
          return;
        } else if (
          // 如果是选中状态则出现颜色列表
          (index == 0 || index == 1) &&
          this.currentInsidePoint === index &&
          !recover
        ) {
          this.$refs.poinstSelect.needshowColorList(true);
          return;
        } else if (index == 0 || index == 1) {
          // 如果选择过颜色就以当前颜色
          if (
            !(
              (index == 0 && this.currentColor1 == "") ||
              (index == 1 && this.currentColor2 == "")
            )
          ) {
            console.log(this.dbclickStatus)

            setPoint(
              this.currentInside,
              "point_" + index,
              "click",
              "",
              this.currentColor1,
              this.currentColor2
            );


            this.$router.replace({
              query: { ...this.$route.query, SimPoint: "point_-1" },
            });
          } else {
            // 如果还没选择过颜色
            this.changePointFn("inside", -2);
          }
        }

        // 正常情况下
        if (
          index === -2 ||
          (this.currentInsidePoint === index &&
            !recover &&
            this.insidePointDbStatus !== 1)
        ) {
          // 取消选中
          this.currentInsidePoint = -1;

          setPoint(this.currentInside, "");


          this.$router.replace({
            query: { ...this.$route.query, SimPoint: "point_" + -1 },
          });
        } else {
          this.currentInsidePoint = index;
          if (index !== 2 && !recover) {
            this.$refs.poinstSelect.needshowColorList(true);
            return;
          }
          let Colorarr1 = [
            this.currentColor1 === "" ? "#fff" : this.currentColor1,
            this.currentColor2 === "" ? "#000" : this.currentColor2,
          ];


          setPoint(
            this.currentInside,
            "point_" + index,
            "click",
            "",
            Colorarr1[0],
            Colorarr1[1]
          );



          this.$router.replace({
            query: { ...this.$route.query, SimPoint: "point_" + index },
          });
          if (this.insidePointDbStatus == 1) {
            setPoint(this.currentOutside, "point_" + index);
          }
        }
      } else {
        if (
          this.currentOutsidePoint === index &&
          !recover &&
          this.outsidePointDbStatus !== 1
        ) {
          // 取消选中
          this.currentOutsidePoint = -1;
          setLight(this.currentOutside, "");
          this.$router.replace({
            query: { ...this.$route.query, SimLight: "light_" + -1 },
          });
        } else {
          this.currentOutsidePoint = index;
          setLight(this.currentOutside, "light_" + index);
          this.$router.replace({
            query: { ...this.$route.query, SimLight: "light_" + index },
          });
          if (this.outsidePointDbStatus == 1) {
            setLight(this.currentInside, "light_" + index);
          }
        }
      }
    },
    // 改变拟合颜色
    changeSprinkleColor(index, color) {
      if (index === 0) {
        // 判断颜色是否相同
        // if (this.currentColor1 === color) {
        //   // 取消选中状态
        //   this.changePointFn("inside", -2);
        // } else {
        this.currentColor1 = color;

        setPoint(
          this.currentInside,
          "point_" + index,
          "click",
          "",
          this.currentColor1,
          this.currentColor2
        );

        // }
      } else if (index === 1) {
        // if (this.currentColor2 == color) {
        //   this.changePointFn("inside", -2);
        // } else {
        this.currentColor2 = color;

        setPoint(
          this.currentInside,
          "point_" + index,
          "click",
          "",
          this.currentColor1,
          this.currentColor2
        );

        // }
      }
    },

    // 改变当前选中的内外涂数据
    changeCurrentColor(type, index) {
      if (type === "inside") {
        this.currentInside = this.insideData[index];
      } else {
        this.currentOutside = this.outsideData[index];
      }
    },
    // 改变内涂双击状态
    insideDb(type = '') {
      let _this = this
      if (!this.dbStatus) {
        this.dbStatus = true
        // 代表双击了内涂
        this.insideDbStatus = this.insideDbStatus === 1 ? 0 : 1;
        if (type == 'search') {
          if (this.outsideDbStatus === 1) {
            this.outsideDbStatus = 0

          }
          this.insideDbStatus = 1
          console.log(this.currentInside)
          if (this.currentInside.SwitchAO == 1) {
            this.insideDbStatus = this.outsideDbStatus = 0
          }
        }

        if (this.insideDbStatus === 1) {
          for (
            let i = 0;
            i < document.querySelectorAll(".activeColorOutside").length;
            i++
          ) {
            document.querySelectorAll(".activeColorOutside")[
              i
            ].style.borderColor = "transparent";
          }
          // 内涂双击状态
          SetColor(
            this.currentInside,
            "outside",
            "dbclick",
            "",
            this.currentProduct
          );
          this.$router.replace({ query: { ...this.$route.query, dbclick: 1 } });



        } else {
          for (
            let i = 0;
            i < document.querySelectorAll(".activeColorOutside").length;
            i++
          ) {
            document.querySelectorAll(".activeColorOutside")[
              i
            ].style.borderColor = "rgba(0,0,0,.2)";
          }
          // 取消内涂双击状态
          SetColor(
            this.currentOutside,
            "outside",
            "click",
            "",
            this.currentProduct
          );
          SetColor(
            this.currentInside,
            "inside",
            "click",
            "",
            this.currentProduct
          );
          this.$router.replace({ query: { ...this.$route.query, dbclick: 0 } });
        }

        setTimeout(() => {
          _this.dbStatus = false
        }, 500);

      }



    },
    // 改变外涂双击状态
    outsideDb(type = '') {

      let _this = this
      if (!this.dbStatus) {
        this.dbStatus = true
        this.outsideDbStatus = this.outsideDbStatus == 1 ? 0 : 1;
        if (type == 'search') {
          if (this.insideDbStatus === 1) {
            this.insideDbStatus = 0

          }
          this.outsideDbStatus = 1
          /*if (this.currentOutside.SwitchAO == 1) {
            this.insideDbStatus = this.outsideDbStatus = 0
          }*/
        }

        console.log('dddd', this.outsideDbStatus)
        if (this.outsideDbStatus === 1) {
          for (
            let i = 0;
            i < document.querySelectorAll(".activeColorInside").length;
            i++
          ) {
            document.querySelectorAll(".activeColorInside")[i].style.borderColor =
              "transparent";
          }
          // 外涂双击状态
          SetColor(
            this.currentOutside,
            "inside",
            "dbclick",
            "",
            this.currentProduct
          );
          this.$router.replace({ query: { ...this.$route.query, dbclick: 2 } });


        } else {
          console.log('123');
          for (
            let i = 0;
            i < document.querySelectorAll(".activeColorInside").length;
            i++
          ) {
            document.querySelectorAll(".activeColorInside")[i].style.borderColor =
              "rgba(0,0,0,.2)";
          }
          SetColor(
            this.currentInside,
            "inside",
            "click",
            "",
            this.currentProduct
          );
          SetColor(
            this.currentOutside,
            "outside",
            "click",
            "",
            this.currentProduct
          );
          this.$router.replace({ query: { ...this.$route.query, dbclick: 0 } });
        }
        setTimeout(() => {
          _this.dbStatus = false
        }, 500);
      }

    },
    // 改变拟合双击状态
    changePointDb(type, index) {
      if (this.insideDbStatus === 1 || this.outsideDbStatus === 1) return;
      let _this = this;
      if (type == "inside") {
        if (this.outsidePointDbStatus == 1) return;
        this.currentInsidePoint = index;
        this.insidePointDbStatus = this.insidePointDbStatus === 0 ? 1 : 0;
        this.$router.replace({
          query: {
            ...this.$route.query,
            dbclick: this.insidePointDbStatus,
            SimPoint: "point_" + index,
          },
        });
        this.outsidePointDbStatus = 0;
        if (this.insidePointDbStatus == 1) {
          let color1 = this.currentColor1 == "" ? "#fff" : this.currentColor1;
          let color2 = this.currentColor2 == "" ? "#000" : this.currentColor2;
          setPoint(
            this.currentInside,
            "point_" + index,
            "dbclick",
            "",
            color1,
            color2
          );
        } else {
          SetColor(
            this.currentOutside,
            "outside",
            "click",
            "",
            "",
            function () {
              _this.changePointFn("outside", _this.currentOutsidePoint, true);
            }
          );
        }
      } else {
        if (this.insidePointDbStatus == 1) return;
        this.currentOutsidePoint = index;
        this.outsidePointDbStatus = this.outsidePointDbStatus === 0 ? 1 : 0;
        let dbclick = this.outsidePointDbStatus === 1 ? 2 : 0;
        this.$router.replace({
          query: { ...this.$route.query, dbclick, SimLight: "light_" + index },
        });
        this.insidePointDbStatus = 0;
        if (this.outsidePointDbStatus == 1) {
          setLight(this.currentOutside, "light_" + index, "dbclick");
        } else {
          SetColor(this.currentInside, "inside", "click", "", "", function () {
            _this.changePointFn("inside", _this.currentInsidePoint, true);
          });
        }
      }
      clearTimeout(this.timer);
    },
    // 改变内外涂编号
    changeColorIndex(type, index) {
      if (type == "inside") {
        this.changeCurrentColor("inside", index);
        this.insideColor_index = index;
        this.$router.replace({
          query: { ...this.$route.query, inside: this.insideColor_index },
        });
      } else {
        this.changeCurrentColor("outside", index);
        this.outsideColor_index = index;
        this.$router.replace({
          query: { ...this.$route.query, outside: this.outsideColor_index },
        });
      }
    },
    // 显示/隐藏热门色弹窗
    changeShowHot(bool) {
      this.ShowHot = bool;
    },
    toolClick() {
      this.tool_show = !this.tool_show;
    },
    changeAnime() {
      this.inAnimation = false;
    },
    // 关闭更新弹窗
    closeVersion() {
      this.ShowVersion = false;
    },
    tabClick(index) {
      console.log(index)
      this.aboutusStatus = false
      this.shoucangStatus = false
      this.lishiStatus = false
      this.showLogin = false
      this.inAnimation = true;
      let _this = this

      this.$store.commit('setGuide', index)
      if (index === "icon-0" || index === "icon-3") {
        this.$router.replace({
          query: {
            ...this.$route.query,
            guide: index.split("-")[1],
          },
        });
      } else {
        this.$router.replace({
          query: {
            ...this.$route.query,
            dbclick: 0,
          },
        });
      }


       
      if (index === "icon-2") {
        setTimeout(() => {
          this.$refs.Othersop.icsClick(0);
        }, 200);
      }

      if (index === "icon-1") {
        setTimeout(() => {
          this.$refs.production.ocsClick(this.getUrlKey("product"));
        }, 200);
      }
      this.tabbar_index = index;
      this.guide = this.tabbar_index;
      if (this.guide === "icon-0") {
        if (this.insideDbStatus !== 1 && this.outsideDbStatus !== 1) {
          SetColor(
            this.currentInside,
            "inside",
            "click",
            "",
            this.currentProduct
          );
          SetColor(
            this.currentOutside,
            "outside",
            "click",
            "",
            this.currentProduct
          );
        } else {
        }
      } else if (this.guide !== "icon-1") {
        // 初始化双击状态
        if (this.insideDbStatus === 1) {
          this.insideDb();
        }
        if (this.outsideDbStatus === 1) {
          this.outsideDb();
        }
      }
      if (this.guide === "icon-1") {
        // 如果是从颜色界面过来的话
        if (this.insideDbStatus !== 1 && this.outsideDbStatus !== 1) {
          SetColor(
            this.currentInside,
            "inside",
            "click",
            "",
            this.currentProduct
          );
          SetColor(
            this.currentOutside,
            "outside",
            "click",
            "",
            this.currentProduct
          );
        } else {
          this.insidePointDbStatus = this.insideDbStatus;
          this.outsidePointDbStatus = this.outsideDbStatus;
        }
      } else {
        this.currentInsidePoint = -1;
        this.insidePointDbStatus = 0;
        this.currentOutsidePoint = -1;
        this.outsidePointDbStatus = 0;
        // 初始化双击状态
        // if (this.insideDbStatus === 1) {
        //   this.insideDb();
        // }
        // if (this.outsideDbStatus === 1) {
        //   this.outsideDb();
        // }
        this.$router.replace({
          query: {
            ...this.$route.query,
            SimLight: "light_" + _this.currentOutsidePoint,
            SimPoint: "point_" + _this.currentInsidePoint,
          },
        });
      }
      this.$store.commit("setFromIcon", this.$store.getters.getIcon);
      this.$store.commit("setIcon", index);
    },

    getManifier(data) {
      document.getElementsByClassName("v3d-canvas")[0].style.transform =
        "scale(" + 2 + ")";
      document.getElementsByClassName("v3d-canvas")[0].style.transition =
        "0.36s";
      document.getElementsByClassName("v3d-canvas")[0].style.animation =
        "vague 0.36s 0s linear 1";
      document.getElementsByClassName("v3d-canvas")[0].style[
        "animation-fill-mode"
      ] = "forwards";
      document.getElementById("showMask").className = "showMaskin";
      setTimeout(function () {
        v3dAppAPI.SetSceenScale(1.75);
      }, 360);

      //this.doManifier()
      this.magnifierStatus = data;
    },
    // 文本的显示隐藏
    changeTextShow(type, data) {
      if (type === "inside") {
        this.inside_text_show = data;
      } else if (type === "outside") {
        this.outside_text_show = data;
      }
    },
    exitMagnifier() {
      document.getElementsByClassName("v3d-canvas")[0].style.transform =
        "scale(1)";
      document.getElementsByClassName("v3d-canvas")[0].style.filter =
        "blur(0px)";
      document.getElementById("showMask").className = "showMaskout";
      this.magnifierStatus = false;
      v3dAppAPI.SetSceenScale(1);
    },
    convertCanvasToImage(canvas) {
      let image = new Image();
      image.src = canvas.toDataURL("image/png");
      return image.src;
    },
    // 自定义画布截图
    imgCut(context, image, imgElementW, imgElementH, sx, sy, w, h) {
      //清理画布，便于重新绘制
      context.clearRect(0, 0, imgElementW, imgElementH);
      //计算 ：比例 = 原图像/显示图像
      var ratioW = image.width / imgElementW;
      var ratioH = image.height / imgElementH;
      //根据截取图像的所占位置及大小计算出在原图所占的位置及大小
      //.drawImage(图像对象,原图像截取的起始X坐标,原图像截取的起始Y坐标,原图像截取的宽度,原图像截取的高度，
      // 绘制图像的起始X坐标,绘制图像的起始Y坐标,绘制图像所需要的宽度,绘制图像所需要的高度);
      context.drawImage(
        image,
        ratioW * sx,
        ratioH * sy,
        ratioW * w,
        ratioH * h,
        0,
        0,
        w,
        h
      );
    },
    takeScreenImage(callback) {
      let _this = this;
      // 获取屏幕宽度高度
      let screenWidth = document.body.clientWidth;
      let screenHeigth = document.body.clientHeight;
      let screenshotWidth;
      let screenshotHeight;
      if (screenWidth / 0.7 > screenHeigth) {
        // 以高度截图
        screenshotWidth = screenHeigth * 0.7;
        screenshotHeight = screenHeigth;
      } else {
        // 以宽度截图
        screenshotHeight = screenWidth / 0.7;
        screenshotWidth = screenWidth;
      }
      let startX = (screenWidth - screenshotWidth) / 2;
      let startY = (screenHeigth - screenshotHeight) / 2;
      let nowCanvas = document.createElement("canvas");
      nowCanvas.width = screenshotWidth;
      nowCanvas.height = screenshotHeight;
      let nowContext = nowCanvas.getContext("2d");
      let image = new Image();
      image.src = document.querySelector(".v3d-canvas").toDataURL("image/png");
      console.log(image.src)
      image.onload = function () {
        //清理画布，便于重新绘制
        _this.imgCut(
          nowContext,
          image,
          screenWidth,
          screenHeigth,
          startX,
          startY,
          screenshotWidth,
          screenshotHeight
        );
        if (callback) callback(nowCanvas, image);
      };
    },
    sendImg() {

      //判断当前用户是否登录
      let token = localStorage.getItem('token')
      if (!token) {
        //用户未登录  跳转到登录页面
        this.toLogin()
      }

      // 判断现在长度
      /*  if (this.collectList.length >= 20) {
          this.tipMsg = true;
          clearTimeout(this.tipTimer);
          this.tipTimer = setTimeout(() => {
            this.tipMsg = false;
          }, 2000);
          return;
        }*/
      // 用户点击收藏
      let _this = this;
      // 收藏的数据
      let collect = {};
      // 获取模型类型
      collect.product = this.currentProduct;
      v3dAppAPI.TweenCamera("start-camera", "start-target", 0.2, function () {
        _this.takeScreenImage(function (nowCanvas, image) {
          collect.src = nowCanvas.toDataURL("image/png");
          _this.collectImg = image.src;
          // 执行动画
          _this.$refs.collectAnime.style.display = "block";
          setTimeout(() => {
            _this.$refs.collectAnime.style.display = "none";
          }, 1000);


          // 是否内外涂颜色一致
          if (_this.insideDbStatus === 1 || _this.insidePointDbStatus === 1) {
            collect.dbclick = "inside";
          } else if (
            _this.outsideDbStatus == 1 ||
            _this.outsidePointDbStatus === 1
          ) {
            collect.dbclick = "outside";
          } else {
            collect.dbclick = "none";
          }
          // 保存内涂数据
          collect.insideColor = _this.insideColor_index;
          // 保存外涂颜色
          collect.outsideColor = _this.outsideColor_index;
          collect.SimPoint =
            _this.currentInsidePoint !== -1 && _this.guide === "icon-1"
              ? "SimPoint_" + _this.currentInsidePoint
              : "";
          collect.SimLight =
            _this.currentOutsidePoint !== -1 && _this.guide === "icon-1"
              ? "SimLight_" + _this.currentOutsidePoint
              : "";
          collect.color1 =
            _this.currentColor1 == "" ? "#fff" : _this.currentColor1;
          collect.color2 =
            _this.currentColor2 == "" ? "#000" : _this.currentColor2;
          _this.collectList.push(collect);
          console.log(collect);

          let postData = {
            ntId: _this.insideData[collect.insideColor].dataId,
            saParam: collect['SimPoint'],
            sgParam: collect['SimLight'],
            wtId: _this.outsideData[collect.outsideColor].dataId,

          }

          let fileData = {
            base64Str: collect.src,
            newFileName: postData.ntId + postData.wtId
          }


          _this.$ajax({
            method: "post",
            url: _this.$global.serverSrc + "/jeecg-boot/api/common/base64ToImage",
            data: fileData
          }).then((res1) => {

            if (res1.data.code == 200) {
              postData.ntIcon = res1.data.result
              _this.$ajax({
                method: "post",
                url: _this.$global.serverSrc + "/jeecg-boot/color/api/userHistory",

                data: postData
              }).then((res) => {

                if (res.data.code == 200) {
                  _this.$layer.msg("收藏成功")


                } else {
                  _this.$layer.msg(res.data.message)
                }
              }).catch(e => {
                _this.toLogin()
              })
            }


          })




        });

      });





    },
    // 删除收藏
    deleCollect(index) {
      // console.log(index);
      this.collectList.splice(index, 1);
    },

    getTextInfo(val) {
      console.log(val)
      if (!Array.isArray(val)) {
        if (this.languageType == "en") {
          return (val = val[`textEN`]);
        } else if (this.languageType == "cn") {
          return (val = val[`textCN`]);
        }
      } else {
        return val;
      }
    },
    showInsideInfo(val) {
      this.top_text1 = this.getTextInfo(val);
    },
    showOutsideInfo(val) {
      this.top_text2 = this.getTextInfo(val);
    },
    doMemberForm(type) {
      if (type != "login" && type != "reg") {
        type = "";
      }
      this.memberFormtype = type;
      this.PageStatus = false;
      this.tool_show = false;
    },
    showPageView() {
      this.memberFormtype = "";
      this.PageStatus = true;
    },

    copyText(text) {
      // 数字没有 .length 不能执行selectText 需要转化成字符串
      const textString = text.toString();
      let input = document.querySelector('#copy-input');
      if (!input) {
        input = document.createElement('input');
        input.id = "copy-input";
        input.readOnly = "readOnly";        // 防止ios聚焦触发键盘事件
        input.style.position = "absolute";
        input.style.left = "-1000px";
        input.style.zIndex = "-1000";
        document.body.appendChild(input)
      }

      input.value = textString;
      // ios必须先选中文字且不支持 input.select();
      selectText(input, 0, textString.length);
      console.log(document.execCommand('copy'), 'execCommand');
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        this.$layer.msg(this.copySuccessText);
      }
      input.blur();

      // input自带的select()方法在苹果端无法进行选择，所以需要自己去写一个类似的方法
      // 选择文本。createTextRange(setSelectionRange)是input方法
      function selectText(textbox, startIndex, stopIndex) {
        if (textbox.createTextRange) {//ie
          const range = textbox.createTextRange();
          range.collapse(true);
          range.moveStart('character', startIndex);//起始光标
          range.moveEnd('character', stopIndex - startIndex);//结束光标
          range.select();//不兼容苹果
        } else {//firefox/chrome
          textbox.setSelectionRange(startIndex, stopIndex);
          textbox.focus();
        }
      }
    },


    doCopy() {
      var _this = this
      this.openLoading('copy');

      let inID = this.insideData[this.insideColor_index].dataId
      let outID = this.outsideData[this.outsideColor_index].dataId
      let url = location.href + "&inID=" + inID + "&outID=" + outID
      _this.$ajax
        .get(_this.$global.serverSrc + "/jeecg-boot/color/api/generator?fullUrl=" + Base64.encode(url))
        .then((data) => {
          console.log(data.data.result)
          _this.closeLoading();

          if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
            _this.shareUrl = data.data.result
            _this.copyStatus = true
          } else {
            _this.copyText(data.data.result)
          }


          /*  v3dAppAPI.TweenCamera("start-camera", "start-target", 0.2, function () {
            // 在第一个二个页面
            if (_this.guide === "icon-0" || _this.guide === "icon-1") {
              html2canvas(_this.$refs.imageDom, {
                width: 400,
                height: _this.$refs.imageDom.clientHeight,
              }).then((img) => {
                let imgUrl = img.toDataURL("image/png");
                _this.takeScreenImage(function (nowCanvas, image) {
                  imageUrl = nowCanvas.toDataURL("image/png");
                
                  _this.$refs.copyCom.getTakeScreem(imageUrl, imgUrl);
                });
              });
            } else if (_this.guide === "icon-2") {
              // 在第三个页面
              for (
                let i = 0;
                i < _this.$refs.production.$refs.productionList.length;
                i++
              ) {
                if (
                  _this.$refs.production.$refs.productionList[i].style.display !==
                  "none"
                ) {
                  html2canvas(_this.$refs.production.$refs.productionList[i], {
                    width: 400,
                    height:
                      _this.$refs.production.$refs.productionList[i].clientHeight,
                  }).then((img) => {
                    let imgUrl = img.toDataURL("image/png");
                    _this.takeScreenImage(function (nowCanvas, image) {
                      imageUrl = nowCanvas.toDataURL("image/png");
                      _this.$refs.copyCom.getTakeScreem(imageUrl, imgUrl);
                    });
                  });
                }
              }
            } else if (_this.guide === "icon-4") {
              html2canvas(_this.$refs.otherContent, {
                width: 400,
                height: _this.$refs.otherContent.clientHeight,
              }).then((img) => {
                let imgUrl = img.toDataURL("image/png");
                _this.takeScreenImage(function (nowCanvas, image) {
                  imageUrl = nowCanvas.toDataURL("image/png");
                  _this.$refs.copyCom.getTakeScreem(imageUrl, imgUrl);
                });
              });
            } else {
              _this.takeScreenImage(function (nowCanvas, image) {
                imageUrl = nowCanvas.toDataURL("image/png");
                _this.$refs.copyCom.getTakeScreem(imageUrl, imgUrl);
              });
            }
          });
           */

        })




    },
    doShare() {
      this.openLoading('share');
      setTimeout(() => {
        this.doShare1();
      }, 100);



    },
    doShare1() {
      // this.shareKey++;
      let _this = this;


      let imageUrl;
      if (_this.guide === "icon-2") {
        // let imageUrl = v3dAppAPI.TakeScreen();
        _this.takeScreenImage(function (nowCanvas, image) {
          imageUrl = nowCanvas.toDataURL("image/png");
          _this.$refs.sharesCom.getTakeScreem(imageUrl, _this.top_text1, _this.top_text2);
          _this.closeLoading();
        });
      } else {
        v3dAppAPI.TweenCamera("start-camera", "start-target", 0.2, function () {
          // 在第一个二个页面
          if (_this.guide === "icon-0" || _this.guide === "icon-1") {

            _this.takeScreenImage(function (nowCanvas, image) {
              imageUrl = nowCanvas.toDataURL("image/png");
              _this.$refs.sharesCom.getTakeScreem(imageUrl, _this.top_text1, _this.top_text2);
              _this.closeLoading();
            });

          }/* else if (_this.guide === "icon-2") {
            // 在第三个页面
            for (
              let i = 0;
              i < _this.$refs.production.$refs.productionList.length;
              i++
            ) {
              if (
                _this.$refs.production.$refs.productionList[i].style.display !==
                "none"
              ) {
                html2canvas(_this.$refs.production.$refs.productionList[i], {
                  width: 400,
                  height:
                    _this.$refs.production.$refs.productionList[i].clientHeight,
                }).then((img) => {
                  let imgUrl = img.toDataURL("image/png");
                  _this.takeScreenImage(function (nowCanvas, image) {
                    imageUrl = nowCanvas.toDataURL("image/png");
                    _this.$refs.sharesCom.getTakeScreem(imageUrl, _this.top_text1, _this.top_text2);
                    _this.closeLoading();
                  });
                });
              }
            }
          } */else if (_this.guide === "icon-4") {
            html2canvas(_this.$refs.otherContent, {
              width: 400,
              height: _this.$refs.otherContent.clientHeight,
            }).then((img) => {
              let imgUrl = img.toDataURL("image/png");
              _this.takeScreenImage(function (nowCanvas, image) {
                imageUrl = nowCanvas.toDataURL("image/png");
                _this.$refs.sharesCom.getTakeScreem(imageUrl, _this.top_text1, _this.top_text2);
                _this.closeLoading();
              });
            });
          } else {
            _this.takeScreenImage(function (nowCanvas, image) {
              imageUrl = nowCanvas.toDataURL("image/png");
              _this.$refs.sharesCom.getTakeScreem(imageUrl, _this.top_text1, _this.top_text2);
              _this.closeLoading();
            });
          }
        });
      }

      this.sharestatus = true;
      this.toolClick();
    },
    closeShare() {
      this.sharestatus = false;
    },
    closeCopy() {
      this.copyStatus = false;
    },
    closeLoading() {
      this.loadingStatus = false
    },
    openLoading(type) {
      this.loadingStatus = type;
    },
    showSearchmsg() {
      console.log("-----")
      this.showSearchType = "msg"
      this.showSearch = true
    },

    searchEmpty() {
      this.showSearchType = 'empty'
      this.showSearch = true
    },
    searchSuccess(msg, colors, index, type) {
      this.showSearchType = "success"
      this.colorLikes = colors
      this.searchData.index = index
      this.searchData.type = type
      this.successMsg = msg
      this.showSearch = true
    },
    searchyingyong() {
      this.$refs.colorselectChild.searchAccurate(this.searchData.index, this.searchData.type);
      this.searchData = {}
      this.showSearch = false
    },
    closeSearchMsg() {
      this.showSearch = false
    },

    showotherInfo(data) {
      console.log(data)
      if (this.languageType == 'en') {
        this.otherTypeImg = this.baseUrl + "/jeecg-boot/" + data.enIcon
      } else {
        this.otherTypeImg = this.baseUrl + "/jeecg-boot/" + data.cnIcon
      }
      console.log(this.otherTypeImg)
      //this.otherImageId = data.split("_")[1];
    },
    getUrlKey(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    checkIcon() {
      var model = this.getUrlKey("model");
      var nnovation = this.getUrlKey("nnovation");
      var SimPoint = this.getUrlKey("SimPoint");
      var SimLight = this.getUrlKey("SimLight");

      if (model == "scene") {
        this.guide = "icon-3";
      } else if (model == "product") {
        if (nnovation != "") {
          // this.guide = "icon-4";
        } else {
          if (SimPoint == "" && SimLight == "") {
            this.guide = "icon-0";
          } else {
            this.guide = "icon-1";
          }
        }
      }
    },
    setLanguage(val) {
      // console.log(val)
      this.languageType = val;


      //
      this.$store.commit("setLanguageType", val);
      if (val === "cn") {
        this.$i18n.locale = "zh-cn"
        this.showInsideInfo(this.currentInside["textCN"]);
        this.showOutsideInfo(this.currentOutside["textCN"]);
        this.share = "分享";
        this.copy = '复制',
          this.shareCopyText = '长按上方链接进行复制'
        this.copySuccessText = '已复制到剪切板'
      } else {
        this.$i18n.locale = "en-us"
        this.showInsideInfo(this.currentInside["textEN"]);
        this.showOutsideInfo(this.currentOutside["textEN"]);
        this.share = "Share";
        this.copy = 'Copy'
        this.shareCopyText = ' Long-press the link and you will copy it '
        this.copySuccessText = 'Copy succeeded'
      }
      //this.$refs.Othersop.icsClick(0);
      // this.$refs.colorselectChild.setLanguage(val);
      this.toolClick();
    },
    // 关闭联系我们
    concatMaskClose() {
      this.showConcat = false;
    },
    toLogin() {
      this.showLogin = true
      this.showReg = false
    },
    toReg() {
      console.log('456')
      this.showReg = true
      this.showLogin = false
    },
    regSuccess() {
      this.showLogin = true
      this.showReg = false
    },
    loginSuccess() {
      console.log('666')
      this.showLogin = false
      this.showReg = false
      this.showUserInfo = false
      this.shoucangStatus = false
      this.lishiStatus = false
      this.aboutusStatus = false
      this.showBindPhoneStatus = false
      this.showAreaStatus = false
      this.showBindEmailStatus = false
      this.$refs.users.getUserInfo()
      this.$refs.users.setlanguage(this.languageType)
    },
    showUser(e) {
      console.log(e)
      this.userInfoData = e
      this.loginSuccess()
    },

    editArea() {
      console.log("6666")
      this.showAreaStatus = true
    },
    showInfo() {


      this.showUserInfo = true
      this.$refs.userInfos.getUserInfo()
    },
    showShoucang() {
      this.shoucangStatus = true
      this.$refs.shoucang.getList()
    },
    showLishi() {
      this.lishiStatus = true
      this.$refs.lishi.getList()
    },
    showAboutUs(e) {
      this.aboutusStatus = true
      this.$refs.aboutus.setlanguage(this.languageType)
      this.$refs.aboutus.getInfo(e)
    },
    addLishi() {
      let _this = this
      let collect = {}
      // 是否内外涂颜色一致
      if (_this.insideDbStatus === 1 || _this.insidePointDbStatus === 1) {
        collect.dbclick = "inside";
      } else if (
        _this.outsideDbStatus == 1 ||
        _this.outsidePointDbStatus === 1
      ) {
        collect.dbclick = "outside";
      } else {
        collect.dbclick = "none";
      }
      // 保存内涂数据
      collect.insideColor = _this.insideColor_index;
      // 保存外涂颜色
      collect.outsideColor = _this.outsideColor_index;
      collect.SimPoint =
        _this.currentInsidePoint !== -1 && _this.guide === "icon-1"
          ? "SimPoint_" + _this.currentInsidePoint
          : "";
      collect.SimLight =
        _this.currentOutsidePoint !== -1 && _this.guide === "icon-1"
          ? "SimLight_" + _this.currentOutsidePoint
          : "";
      collect.color1 =
        _this.currentColor1 == "" ? "#fff" : _this.currentColor1;
      collect.color2 =
        _this.currentColor2 == "" ? "#000" : _this.currentColor2;
      _this.collectList.push(collect);
      console.log(collect);

      let postData = {
        ntId: _this.insideData[collect.insideColor].dataId,
        saParam: collect['SimPoint'],
        sgParam: collect['SimLight'],
        wtId: _this.outsideData[collect.outsideColor].dataId,

      }

      _this.$ajax({
        method: "post",
        url: _this.$global.serverSrc + "/jeecg-boot/color/api/userColl",
        data: postData
      }).then((res) => {
        console.log(res)

      })
    },
    getMoreData(type = 0, rgbValue, isP1 = false, keyWord = "") {
      console.log(type)
      let _this = this
      let data = {
        aroundType: type,
        pageSize: 30,
        rgbValue: rgbValue,
        keyWord: keyWord
      }

      if (type == 0) {
        if (isP1) {
          this.inPageNo = 0
        }
        this.inPageNo++
        data.pageNo = this.inPageNo
      }
      if (type == 1) {
        if (isP1) {
          this.outPageNo = 0
        }
        this.outPageNo++
        data.pageNo = this.outPageNo
      }

      this.$ajax({
        method: "post",
        url: _this.$global.serverSrc + "/jeecg-boot/color/api/selectColor",
        data
      }).then((res) => {

        if (res.data.code == 200) {

          var insideList = this.insideData
          var outsideList = this.outsideData

          if (isP1 && type == 0) {
            insideList = []

            this.$router.replace({
              query: {
                ...this.$route.query,
                inside: 0,
              },
            });

          }
          if (isP1 && type == 1) {
            outsideList = []
            this.$router.replace({
              query: {
                ...this.$route.query,
                outside: 0,
              },
            });
          }


          console.log(isP1, type)

          res.data.result.records.forEach(function (item) {
            var insideItem = {}
            if (item.colorJson) {
              var jsonData = JSON.parse(item.colorJson)

              insideItem.Material = jsonData.Material
              insideItem.MaterialId = item.materialId
              insideItem.Light = jsonData.Light
              insideItem.Value = jsonData.Value
              insideItem.Size = jsonData.Size
              insideItem.Switch = jsonData.Switch
              insideItem.Color = jsonData.Color
              insideItem.keyword = item.keyWord
              insideItem.recommend = item.valueIds != "" ? item.valueIds.split(',') : [200, 197, 64]
              insideItem.dataId = item.id
              insideItem.colorCode = item.colorCode
              insideItem.aliasName = item.aliasName
              insideItem.SwitchAO = item.SwitchAO ? 1 : 0

              if (type == 0) {
                insideItem.Material = insideItem.Material
                insideItem.textCN = [item.materialId_dictText, "内涂系列", item.colorCode, item.znName]
                insideItem.textEN = [item.materialId_dictText, "Interior", item.colorCode, item.enName]
                insideItem.id = insideList.length
                insideList.push(insideItem)
              } else {
                insideItem.Material = insideItem.Material
                insideItem.textCN = [item.materialId_dictText, "外涂系列", item.colorCode, item.znName]
                insideItem.textEN = [item.materialId_dictText, "Exterior", item.colorCode, item.enName]
                insideItem.id = insideList.length
                outsideList.push(insideItem)
              }


            }
          })

          this.insideData = insideList
          this.outsideData = outsideList
          console.log(outsideList)
          localStorage.setItem('insideData', JSON.stringify(insideList))
          localStorage.setItem('outsideData', JSON.stringify(outsideList))
          console.log(type)
          if ((keyWord || rgbValue) && isP1) {
            _this.$refs.colorselectChild.initData('fuxian', type == '0' ? 'inside' : 'outside');
            _this.$refs.colorselectChild4.initData('fuxian', type == '0' ? 'inside' : 'outside');
          } else {
            _this.$refs.colorselectChild.initData();
            _this.$refs.colorselectChild4.initData();

         
          }


        }
      })
    },
    scFuxian(id) {
      let _this = this
      let data = { id }
      this.$ajax({
        method: "post",
        url: _this.$global.serverSrc + "/jeecg-boot/color/api/get/userHis/color?id=" + id,
        data
      }).then((res) => {

        if (res.data.code == 200) {
          let inData = _this.formatColorJson(res.data.result.inside)
          let outData = _this.formatColorJson(res.data.result.outside)

          var insideList = _this.insideData
          var outsideList = _this.outsideData

          insideList.unshift(inData)
          outsideList.unshift(outData)
          _this.$router.replace({ query: { ..._this.$route.query, inside: 0 } });
          _this.$router.replace({ query: { ..._this.$route.query, outside: 0 } });
          localStorage.setItem('insideData', JSON.stringify(insideList))
          localStorage.setItem('outsideData', JSON.stringify(outsideList))
          _this.$refs.colorselectChild.initData('fuxian');
          _this.$refs.colorselectChild4.initData('fuxian');
          _this.tabClick('icon-0')

        }
      })
    },
    lsFuxian(id) {
      let _this = this
      let data = { id }
      this.$ajax({
        method: "post",
        url: _this.$global.serverSrc + "/jeecg-boot/color/api/get/userColl/color?id=" + id,
        data
      }).then((res) => {

        if (res.data.code == 200) {
          let inData = _this.formatColorJson(res.data.result.inside)
          let outData = _this.formatColorJson(res.data.result.outside)

          var insideList = _this.insideData
          var outsideList = _this.outsideData

          insideList.unshift(inData)
          outsideList.unshift(outData)
          _this.$router.replace({ query: { ..._this.$route.query, inside: 0 } });
          _this.$router.replace({ query: { ..._this.$route.query, outside: 0 } });
          localStorage.setItem('insideData', JSON.stringify(insideList))
          localStorage.setItem('outsideData', JSON.stringify(outsideList))
          _this.$refs.colorselectChild.initData('fuxian');
          _this.$refs.colorselectChild4.initData('fuxian');
          _this.tabClick('icon-0')

        }
      })
    },

    searchFuxian(item, type) {
      console.log(item, type)
      this.$router.replace({ query: { ...this.$route.query, dbclick: 0 } });
      let _this = this
      let inData = _this.formatColorJson(item)
      var insideList = _this.insideData
      var outsideList = _this.outsideData
      if (type == 'inside') {
        insideList.unshift(inData)
        _this.$router.replace({ query: { ..._this.$route.query, inside: 0 } });
      } else {
        outsideList.unshift(inData)
        _this.$router.replace({ query: { ..._this.$route.query, outside: 0 } });
      }
      localStorage.setItem('insideData', JSON.stringify(insideList))
      localStorage.setItem('outsideData', JSON.stringify(outsideList))
      _this.$refs.colorselectChild.initData('fuxian', type);
      _this.$refs.colorselectChild4.initData('fuxian', type);
    },

    formatColorJson(item) {
      var insideItem = {}
      if (item.colorJson) {
        var jsonData = JSON.parse(item.colorJson)

        insideItem.Material = jsonData.Material
        insideItem.MaterialId = item.materialId
        insideItem.Light = jsonData.Light
        insideItem.Value = jsonData.Value
        insideItem.Size = jsonData.Size
        insideItem.Switch = jsonData.Switch
        insideItem.Color = jsonData.Color
        insideItem.keyword = item.keyWord
        insideItem.recommend = item.valueIds != "" ? item.valueIds.split(',') : [200, 197, 64]
        insideItem.dataId = item.id
        insideItem.colorCode = item.colorCode
        insideItem.aliasName = item.aliasName
        insideItem.SwitchAO = item.SwitchAO ? 1 : 0
        insideItem.typeId = item.typeId

        if (item.typeId == 1645432834) {
          insideItem.Material = insideItem.Material
          insideItem.textCN = [item.materialId_dictText, "内涂系列", item.colorCode, item.znName]
          insideItem.textEN = [item.materialId_dictText, "Interior", item.colorCode, item.enName]
        } else {
          insideItem.Material = insideItem.Material
          insideItem.textCN = [item.materialId_dictText, "外涂系列", item.colorCode, item.znName]
          insideItem.textEN = [item.materialId_dictText, "Exterior", item.colorCode, item.enName]
        }


      }
      return insideItem

    },
    noLogin() {
      this.tabClick('icon-0')
    },
    showBindPhone() {
      this.showBindPhoneStatus = true
      this.$refs.userInfos.getUserInfo()
    },
    showBindEmail() {

      this.showBindEmailStatus = true
    },
    showtips(type) {
      this.$layer.msg('双击当前颜色，取消内外同色')
    }


  },
  beforeDestroy() {
    if (this.$options.app) {
      this.$options.app.dispose();
      this.$options.app = null;
    }
  }
};
</script>

<style lang="less">
@import "../Ledmodule/model.css";

.fade-enter,
.fade-leave-to {
  /* //进入前，离开后 */
  opacity: 0;
}

.fade-enter-to,
.fade-leave {
  /* //进入后，离开前 */
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.home {
  /* border: 1px solid red; */
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;

  .v3dApp {
    width: 100%;
    height: 100%;
  }
}

.content1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: none;
  transition: all 0.5s;

  .header {
    padding: 10px 10px 0 22px;
  }
}

.logo {
  height: 50px;
  width: auto;
}

.logo img {
  height: 100%;
  width: auto;
}

.header-text {
  position: relative;
  padding-top: 5px;
  font-family: sans-serif;
  background-color: transparent;
}

.title2 {
  margin-top: 5px;
}

.title-tips {
  font-size: 12px;
  color: #ababb2;
  font-weight: 400;
}

.tools {
  position: absolute;
  pointer-events: all;
  top: -40px;
  right: 10px;
  border-radius: 8px;
  background-color: #797979;

  cursor: pointer;
}

.tools-contact {
  position: absolute;
  pointer-events: all;
  top: 20px;
  right: 10px;
  border-radius: 8px;
  background-color: #797979;

  cursor: pointer;
}

.tool {
  width: 50px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tool-padding {
  padding: 8px 0;
}

.concat {
  width: 25px;
  height: 25px;
  filter: invert(1) brightness(1.2);

}

.concat1 {
  width: 22px;
  height: auto;
  padding-bottom: 10px;
}

.tool-img {
  width: 20px;
  height: 20px;
}

.tool-line {
  width: 40px;
  height: 1px;
  background-color: #909090;
  margin: auto;
}

.tool-text {
  font-size: 10px;
  color: #fff;
  margin-top: 3px;
  font-weight: 400;
}

.Colorselect {
  pointer-events: all;
}

.footer {
  pointer-events: all;
  z-index: 10;
}

.tabbar {
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 8px 5px;

  box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.1);
  // margin-bottom: -100px;

  // animation: upshow 0.3s 0.2s ease 1;
  animation-fill-mode: forwards;
}

.tabbar-item {
  padding: 10px 13px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tab-img {
  width: 25px;
  height: 25px;
  user-select: none;
}

.ExitMagnifier {
  width: 10px;
  right: 20px;
  bottom: 170px;
  position: absolute;
  z-index: 10002;
}

#ExitMagnifier {
  width: 15px;
  height: 15px;
  opacity: 0.8;
  cursor: pointer;
}

@keyframes upshow {
  0% {
    margin-bottom: -30%;
  }

  100% {
    margin-bottom: 0px;
  }
}

// @keyframes upshow1 {
//   0% {
//     transform: translateY(100%);
//   }
//   100% {
//     transform: translateY(0%);
//   }
// }

@keyframes maskShow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes vague {
  0% {
    filter: blur(10px);
  }

  100% {
    filter: blur(0px);
  }
}

.showMaskout {
  width: 100%;
  height: 100%;
  display: block;
  z-index: 9003;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.36s;
  pointer-events: none;
  background-image: url(../assets/options/ScaleMask.png);
  background-position: center center;
  background-size: auto 150%;
}

.showMaskin {
  width: 100%;
  height: 100%;
  display: block;
  z-index: 9003;
  opacity: 1;
  pointer-events: all;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.36s;
  pointer-events: none;
  background-image: url(../assets/options/ScaleMask.png);
  background-position: center center;
  background-size: auto 150%;
}

.modal-window {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10001;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

@media (max-width: 768px) {
  .colorContent {
    transform-origin: 0 0 0;
    background-color: transparent;

    // opacity: 0.5;
    .title {
      margin: 5px 0 10px;
      color: #626061;
      font-weight: 700;

      h4 {
        color: #626061;
        font-size: 1.44vh;
        letter-spacing: 0.21vh;

        span {
          color: #9c9b9b;
          font-size: 1.16vh;
          letter-spacing: 0.17vh;
          font-weight: normal;
        }
      }

      .color_text {
        color: #9c9b9b;
        font-size: 1.2vh;
        letter-spacing: 0.17vh;
        font-weight: normal;
      }
    }
  }
}

@media (min-width: 768px) {
  .colorContent {
    transform-origin: 0 0 0;
    background-color: transparent;

    // opacity: 0.5;
    .title {
      margin: 5px 0 20px;
      color: #626061;
      font-weight: 700;

      h4 {
        color: #626061;
        font-size: 18px;
        letter-spacing: 0.05em;

        span {
          color: #9c9b9b;
          font-size: 14px;
          letter-spacing: 0.05em;
          font-weight: normal;
        }
      }

      .color_text {
        color: #9c9b9b;
        font-size: 14px;
        letter-spacing: 0.05em;
        font-weight: normal;
      }
    }
  }
}

@media (min-width: 1440px) {
  .colorContent {
    transform-origin: 0 0 0;
    background-color: transparent;

    // opacity: 0.5;
    .title {
      margin: 5px 0 20px;
      color: #626061;
      font-weight: 700;

      h4 {
        color: #626061;
        font-size: 24px;
        letter-spacing: 0.05em;

        span {
          color: #9c9b9b;
          font-size: 20px;
          letter-spacing: 0.05em;
          font-weight: normal;
        }
      }

      .color_text {
        color: #9c9b9b;
        font-size: 20px;
        letter-spacing: 0.05em;
        font-weight: normal;
      }
    }
  }
}

.otherContent img {
  width: 600px;
}

@media screen and (max-width: 640px) {
  .otherContent img {
    width: 300px;
  }
}

.collectAnime {
  position: absolute;
  width: 100%;
  height: 100%;
  // pointer-events: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // border: 2px solid #fff;

  animation: collect 0.8s ease-in forwards;
  display: none;

  .collectImg {
    width: 100%;
    height: auto;
  }
}

@keyframes collect {
  0% {
    left: 51%;
    top: 49%;
  }

  10% {
    left: 52%;
    top: 48%;
  }

  100% {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    top: 100%;
    left: 70%;
  }
}
</style>

<style lang="less" scoped>
@media (min-width: 768px) {

  .insideMask,
  .outsideMask {
    height: 60px;
  }
}

@media (max-width: 768px) {

  .insideMask,
  .outsideMask {
    height: 60px;
  }
}

.insideMask {
  position: absolute;
  pointer-events: all;
  width: 100%;
  bottom: 60px;
  transform: translateY(-100%);
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
}

.outsideMask {
  position: absolute;
  pointer-events: all;
  bottom: 60px;
  width: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
}

.tipMsg {
  max-width: 210px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background-color: #f3f3f3;
  padding: 10px;
  margin: 0px;
  font-size: 12px;

  p {
    opacity: 0.8;

    &:first-child {
      text-indent: 2em;
      white-space: nowrap;
    }
  }
}

.copy_view {
  width: 80%;
  margin-left: 10%;
  text-align: center;
  background-color: #EFEFEF;
  border-radius: 20px;
  box-shadow: 2px 2px 0 0 #BEBEBE;
  display: none;
  position: absolute;
  pointer-events: all;
  top: 45vh
}

.copy_view .copy_text_input {
  font-size: 14px;
  font-weight: 600;
  color: #595757;
  border: none;
  background-color: #EFEFEF;
}

.copy_view .copy_btn {
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: #005BAC;
}

.right-icons {
  position: absolute;
  right: 10px;
  bottom: 230px;
  z-index: 6;
}
</style>