<template>
  <div class="shoucang">

    <div class="time-view" @click="visible=true">
      <div  >
        {{ searchTime?searchTime:$t('checkCollectiontime')}}  
      </div>
      <div class="" style="margin-left: 10px;">
        <img src="../assets/user/dowm.png" width="16px" height="9px" alt=""> 

      </div>
     
    </div>

    <div class="sc-list" v-if="list.length>0">


      <div class="sc-item flex" v-for="(item) in list" @click="fuxian(item)" >
        <div class="img-view">
          <img :src="baseUrl + '/jeecg-boot' + item.ntIcon" width="80px" height="80px" style="object-fit: contain" alt="" mode="">
        </div>
        <div class="content-view">
          <div class="flex border-b">
            <div class="sc-title">
              <div> {{ $t("Collectiontime") }}</div>
              <div>{{ item.createTime }}</div>
            </div>
            <div class="flex-end">
              <img src="../assets/user/sced.png" style="margin-top: 10px;" width="16rpx" height="16rpx" alt=""
                @click.stop="unCollect(item.id)">
            </div>
          </div>

          <div class="sc-contnet">
            <div class="inside-info">
             {{item.ntText}}
            </div>
            <div class="outside-info">
              {{item.wtText}}
            </div>
          </div>

        </div>
      </div>

      <div style="width: 100%; height: 120px;"></div>
    </div>

    <div class="list" style="text-align: center; margin-top: 200px;" v-else>
        <img src="../assets/user/nosc.png" style="width: 189px; height: 176px;" alt="">
    </div>


    <div class="footer">
      <div class="login-btn" @click="goback">

        {{ $t("goBack") }}
      </div>
    </div>

    <PickerDate
            :visible.sync="visible"
            :initDate="searchTime"
            start="2023-06-12"
            end="2030-12-11"
            format="YYYY-MM-DD"
            title="日期选择器"
            cancelText="取消"
            confirmText=" 确定"
            :maskClick="false"
            @cancel="cancel"
            @confirm="confirm"
        />
  </div>
</template>
<script>
import 'vue-min-picker/lib/picker.css';//导入样式(建议全局导入)
import pickers from "vue-min-picker";
let {Picker,PickerDate,PickerRegion} = pickers;
export default {
  name: "App",
  data() {
    return {
      loadStatus: false,
      list: [],
      defaultIndex:0,
      pageNo: 1,
      pageSize: 20,
      baseUrl: this.$global.serverSrc,
      searchTime:'',
      visible:false
    }
  },
  components:{
        Picker,
        PickerDate,
        PickerRegion,
    },
  methods: {


  },
  mounted() {
    window.onscroll = (e) => {
      const scrollH = document.documentElement.scrollHeight;// 文档的完整高度 
      const scrollT = document.documentElement.scrollTop || document.body.scrollTop; // 当前滚动条的垂直偏移 
      const screenH = window.screen.height;  // 屏幕可视高度 ;
      if (scrollH - scrollT - screenH == 0) {
        //执行请求接口
        if (this.nextPage == 0) return;//判断若下一页没有则截停
        this.pageNo += 1;
        this.getList();
      }
    }
  }

  ,
  created() {

  },
  methods: {
    goback() {
      this.$emit('showUser')
    },
    getList() {
      let _this = this;
      let data = {
        type: 0,
        pageNo: this.pageNo,
        pageSize: 20,
        date:this.searchTime
      }
      this.$ajax({
        method: "post",
        url: _this.$global.serverSrc + "/jeecg-boot/color/api/get/userHistory/list",

        data
      }).then((res) => {
        console.log(res)
        if (res.data.code == 200) {
         
            _this.list = res.data.result.records
         

        }
      })
    },
    fuxian(item){
      this.$emit('fuxian' , item.id)
    },
    unCollect(id){
       
      let formData = new FormData();
      formData.append("id",id);
      let _this = this
      this.$ajax({
        method: "post",
        url: _this.$global.serverSrc + "/jeecg-boot/color/api/delete/userHistory",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data:formData
      }).then((res) => {
        console.log(res)
        if (res.data.code == 200) {
          _this.list  = []
          this.pageNo = 1;
          this.getList();
          }
      })
    },
    cancel(){

},
confirm(e){
   this.pageNo= 1
   this.searchTime = e
   this.getList()
},

  }
};
</script>
<style  scoped>
.shoucang {
  min-height: 100vh;
  width: 100%;
  background-color: #f0f0f0;
  position: absolute;
  z-index: 10;

  pointer-events: all;
}

.time-view {
  margin: 20px;
  display: flex;
}

.sc-list {
  margin: 20px;
  width: 90%;
  height: 100vh;
  margin: 0 5%;
  overflow-y: scroll;
}

.sc-list::-webkit-scrollbar {
  width: 0;
}

.sc-item {
  margin-top: 10px;
  color: #3B3B3B;
  padding: 10px;
  border-radius: 14px;
  border: 1px #fff solid;
  background-image: linear-gradient(180.21deg, rgba(255, 255, 255, 0.2) -9.27%, rgba(255, 255, 255, 0.5) 60.82%);
}

.flex {
  display: flex;
}

.flex  .img-view{
  position: relative; /* 必须设置为相对定位 */
}
.flex  .img-view image{
  max-width: none !important; /* 重写默认的最大宽度限制 */
    width: auto !important; /* 自动根据图像尺寸进行调整 */
    height: auto !important; /* 同上 */
    object-fit: contain !important; /* 控制图像在容器中的布局方式 */
    vertical-align: middle !important; /* 居中显示图像 */
}


.flex-end {
  margin-left: auto;
}

.img-view {
  width: 60px;
}

.content-view {
  width: calc(100% - 100px);
  margin-left: 20px;
  padding-left: 20px;
}

.border-b {
  padding-bottom: 10px;
  border-bottom: 2px #fff solid;
}

.sc-title {
  font-size: 14px;
  letter-spacing: -0.3px;
  font-weight: 400;

}

.sc-title div {
  line-height: 20px;
}

.sc-contnet {
  margin: 10px 0;
}

.sc-contnet div {
  margin: 10px 0;
  color: #8D8D8D;
  font-size: 14px;
  letter-spacing: -0.3px;
  font-weight: 400;
}

.shoucang .footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 20px 0;
  background-color: #fff;
}

.shoucang .footer .login-btn {
  width: 335px;
  border-radius: 6px;
  margin: auto;
  background-color: #335D9E;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  vertical-align: middle;
  line-height: 20px;
  padding: 10px;
}</style>