module.exports = {
    "shoucang":"收藏",
    "lishi":'历史',
    "userinfo":'个人信息',
    "bindPhone":'绑定手机号',
    "bindEmail":'绑定邮箱',
    "aboutUs":'关于我们',
    "xieyi":'用户协议和隐私政策',
    "editHeadImg":'修改头像',
    "editNickname":'修改昵称',
    "goBack":'返回',
    "clickToLogin":"点击登录账号",
    "inputEmail":'请输入邮箱',
    "inputPsd":'请输入密码',
    "inputPhone":'请输入手机号码',
    "repsd":'确认密码',
    "toReg":'注册',
    "login":'登录',
    "unLogin":'暂无登录',
    "other":'其他方式登录',
    "readXy":'已阅读并同意 《用户协议与隐私政策》。若您的手机号未注册，将为您直接注册账号',
    "inputCode":'请输入验证码',
    "getCode":'获取验证码',
    "reg":'注册账号',
    "hasAccount":'已有账号',
    "toLogin":'去登录',
    "serachTitle":'搜索提示',
    "searchMsg1":' · 输入后半部分型号，如【W8634-54088】中的54088，即可选中对应颜色。 ',
    "searchMsg2":' · 输入潘通色号，如110C，即可选中对应的颜色(内外同色）。注：潘通色搜索只对外涂适用。  ',
    "searchMsg3":' · 输入潘通TPG色号，如12-1106，即可选中对 应的颜色(内外同色)。注: 潘通色搜索只对外 涂适用。',

    "handTitle":'操作提示',
    "handMsg2":' · 双击颜色则实现内外同色；再次双击该颜色则取消内外同色。  ',
    "searchErr":'搜索失败',
    "searchSuccess":'搜索成功',
    "save":'保存',
    "area":"所在地区",
    "guojia":'选择所在国家',
    "diqu":'选择所在省份',
    'logOut':'退出账号',
    'Collectiontime':'收藏时间',
    'checkCollectiontime':'请选择收藏时间',
    'jilushijian':'记录时间',
    'checkjilushijian':'请选择记录时间',
    'dianjichakan':'点击查看',
    'querentuichu':'确认退出',
    'isquerentuichu':'是否确定退出当前账号?',
    'queding':'确定',
    'quxiao':'取消'

}