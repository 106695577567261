<template>
    <div class="Version">
        <div class="Version-container">
            <!-- 提示图片 -->
            <div>
                <div style="line-height: 20px;">
                    <img src="../assets/gth.png" alt="" style="width: 20px; height: 20px;vertical-align: middle;">
                    <span style="vertical-align: middle; padding-left: 10px;color:#205BA0; font-weight: 700;">{{
                        msg.title }}</span>

                </div>

                <div v-if="showSearchType=='success'"   style="font-size: 12px; color: #205BA0; line-height: 18px; margin: 20px 0; border-bottom: 2px solid #bfbfbf;padding-bottom: 20px;">
                    {{ msg.colorCode}}
                </div>

                <div v-if="showSearchType=='success'"   >
                    <div class="flex" v-for="(item,index) in colors">
                        <div> <div style="width: 32px; height: 32px; border-radius: 50%; margin:0 10px 10px; " :style="{'backgroundColor':item.Color[0] }"></div> </div>
                        <div class="article1"> {{item.textEN[0]}}-{{item.textEN[1]}}<br/> {{item.textEN[2]}} {{item.textEN[3]}}</div>
                       
                    </div>
                    
                </div>

                <div style="padding-left: 30px;" class="article-content">
                    <p class="article" v-for="(item , index) in msg.msg">
                        {{item}}
                    </p>
                    
                </div>

                <div style="line-height: 20px;" v-show="msg.title1">
                    <img src="../assets/gth.png" alt="" style="width: 20px; height: 20px;vertical-align: middle;">
                    <span style="vertical-align: middle; padding-left: 10px;color:#205BA0; font-weight: 700;">{{
                        msg.title1 }}</span>

                </div>

                <div style="padding-left: 30px;" class="article-content" v-show="msg.msg1">
                    <p class="article" v-for="(item , index) in msg.msg1">
                        {{item}}
                    </p>
                    
                </div>


            </div>
            <!-- 关闭按钮 -->
            <div class="close">
                <span class="closeBtn"  v-if="showSearchType=='success'"  @click="yingyong" >应用</span>
                <span class="closeBtn1"  @click="close" >关闭</span>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
   
    data() {
        return {
            // 颜色标准文本
            msgInfo: {
                title: this.$t('searchTitle'),
                msg:[
                this.$t('searchMsg1'),
                this.$t('searchMsg2'),
                this.$t('searchMsg3'),
                ],
               
                title1: this.$t('handTitle'),
                msg1:[ this.$t('handMsg2'),]
            },
            msgError:{
                title:  this.$t('searchErr'),
                msg:[
                this.$t('searchMsg1'),
                this.$t('searchMsg2'),
                this.$t('searchMsg3'),
                ]
            },
            msgSuccess:{
                title: this.$t('searchSuccess'),
                colorCode:''
            },

            msg:{}
        };
    },
    props:['showSearchType','successMsg' , 'colors'],
    created() {
        console.log(this.showSearchType , this.colors)
        if(this.showSearchType=='empty'){
            this.msg = this.msgError
        }else if(this.showSearchType=='success'){
            this.msg = this.msgSuccess;
            this.msg.colorCode = this.successMsg
        }else{
            this.msg = this.msgInfo
        }
       
    },
    methods: {
        close() {
            this.$emit("closeSearchMsg");
        },
        yingyong(){
            console.log('uu')
            this.$emit("searchyingyong");
        }
    },
    watch: {
        
    },
};
</script>
  
<style   scoped>
.Version {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color:rgba(255, 255, 255, .5);
    transition:all 0.5s
}

.Version .Version-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 450px;
    background-color: #f4f4f4;
    padding: 30px 20px 10px 20px ;
    box-sizing: border-box;
    box-shadow: -3px 3px 10px rgba(0, 0, 0, 0.3);




}

.close {
    color: #595757;
    font-size: 12px;
    padding-right: 20px;
    float: right;
    margin-top: 5px;

}

.close .closeBtn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #bfbfbf;
    color: #fff;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
}
.close .closeBtn1{
    display: inline-block;
    padding: 10px 20px;
    border: 1px #C0C0C0  solid;
    color: #C0C0C0;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
}
.article-content{
    
      border-bottom: 2px solid #bfbfbf;
      padding-bottom: 10px;
      background-color: #f4f4f4;
      margin-bottom: 8px;
}

.article{
    margin-top: 10px;
    font-size: 12px;
    line-height: 17px;
    padding-right: 20px;
    color: #575757;
    font-weight: normal;
} 


.article1{
    font-size: 12px;
    line-height: 17px;
    padding-right: 20px;
    color: #575757;
    font-weight: normal;
} 

</style>