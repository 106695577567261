import { render, staticRenderFns } from "./AboutUs.vue?vue&type=template&id=5b447630&scoped=true&"
import script from "./AboutUs.vue?vue&type=script&lang=js&"
export * from "./AboutUs.vue?vue&type=script&lang=js&"
import style0 from "./AboutUs.vue?vue&type=style&index=0&id=5b447630&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b447630",
  null
  
)

export default component.exports