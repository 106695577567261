<template>
  <div class="productions">
    <dl
      class="productionList"
      v-for="(item, index) in modelText"
      :key="index"
      v-show="currentProduct == 'Product_' + index"
      ref="productionList"
    >
      <!-- <dt><h4>煎盘</h4></dt> -->
      <dd
        v-show="languageType == 'cn'"
        v-for="(item1, index1) in item.textCN"
        :key="index1 + 10"
      >
        {{ item1 }}
      </dd>
      <dd
        v-show="languageType == 'en'"
        v-for="(item1, index1) in item.textEN"
        :key="index1"
      >
        {{ item1 }}
      </dd>
    </dl>

    <div class="tabbaroption">
      <div class="mask"><!-- glass --></div>
      <div class="topbox">
        <!-- <div class="search"> -->
        <!-- 搜索 -->
        <!-- <input type="text" class="inputed" /> -->
        <!-- <img src="../assets/options/search.png" alt=> -->
        <!-- </div> -->
      </div>
      <!-- <hr /> -->
      <!-- 分割线 -->
      <div class="selector">
        <!-- 滑动选择行1 -->
        <div class="slide">
          <!-- <div class="direction">
              <img src="../assets/options/pre.png" alt="" id="prepng" />
            </div> -->
          <!-- 箭头盒子 -->
          <div class="selected">
            <ul class="litseletor">
              <li
                class="color-selected1"
                v-for="(brand, index) in brandList"
                :key="'brand_' + index"
                :id="'brand_' + index"
              >
                <label
                  :class="{
                    updata_fontcolor: brandselector_index === 'brand_' + index,
                  }"
                  class="plannings"
                  @click="icsClick('brand_' + index)"
                  >{{ brand.name }}</label
                >
              </li>
            </ul>
          </div>
          <!-- inside -->
          <!-- <div class="direction">
              <img src="../assets/options/next.png" alt="" id="nextpng" />
            </div> -->
          <!-- 箭头盒子 -->
        </div>
        <!-- 滑动选择行2 -->
        <div class="slide">
          <!-- <div class="direction">
              <img src="../assets/options/pre.png" alt="" id="prepng2" />
            </div> -->
          <!-- 箭头盒子 -->
          <div class="selected">
            <ul class="litseletor litseletor1">
              <li
                class="color-selected"
                v-for="(outside_colors, index) in outsideColor"
                :key="'Product_' + index"
                :id="'Product_' + index"
                @click="ocsClick('Product_' + index)"
                :style="{
                  background:
                    currentProduct == 'Product_' + index
                      ? 'rgb(0 0 0 / 15%)'
                      : '',
                }"
              >
                <img
                  :class="{
                    updata_fontcolor: product_index === 'outside_' + index,
                  }"
                  draggable="false"
                  class="ocs-img"
                  :src="
                    require(`../assets/productionss/product-${index + 1}.png`)
                  "
                  alt=""
                />
              </li>
            </ul>
          </div>
          <!-- outside -->
          <!-- <div class="direction">
              <img src="../assets/options/next.png" alt="" id="nextpng2" />
            </div> -->
          <!-- 箭头盒子 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SetColor, LoadProduct } from "../Ledmodule/model.js";
export default {
  props: ["languageType", "currentProduct"],
  name: "Productions",
  data() {
    return {
      brandselector_index: "brand_0",
      product_index: "",
      brandList: [{ id: 0, name: "PFLUON" }],
      modelText: [
        {
          textCN: [
            "锅型：煎盘",
            "直径：260 mm",
            "高度：60  mm",
            "手柄长度：180 mm",
          ],
          textEN: [
            "Model:Fry Pan",
            "Diameter:260 mm",
            "Height:60  mm",
            "Handle:180 mm",
          ],
        },
        {
          textCN: [
            "锅型：炒锅",
            "直径：280 mm",
            "高度：80  mm",
            "手柄长度：180 mm",
          ],
          textEN: [
            "Model:Wok",
            "Diameter:280 mm",
            "Height:80  mm",
            "Handle:180 mm",
          ],
        },
        {
          textCN: ["锅型：汤锅", "直径：200 mm", "高度：80  mm"],
          textEN: ["Model:Pot", "Diameter:200 mm", "Height:80  mm"],
        },
      ],
      outsideColor: [
        { id: 0, src: "../assets/productionss/product-1.png" },
        { id: 1, src: "../assets/productionss/product-2.png" },
        { id: 2, src: "../assets/productionss/product-3.png" },
      ],
    };
  },
  created() {},

  methods: {
    initData(){
      let insideData = JSON.parse(localStorage.getItem('baseInsideData'))
      let outsideData = JSON.parse(localStorage.getItem('baseOutsideData'))

      SetColor(
          insideData,
          "inside",
          "click",
          "",
          this.getUrlKey("product")
        );
        SetColor(
          outsideData,
          "outside",
          "click",
          "",
          this.getUrlKey("product")
        );

    },

    getUrlKey(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },

    icsClick(index) {
      this.bindelector_index = index;
    },
    ocsClick(index) {
      this.$emit("changeCurrentProduct", index);
    },

    getUrlKey(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
  },
};
</script>


<style lang="less" scoped>
.productionList {
  position: absolute;
  list-style: none;

  top: 70px;
  left: 22px;
}
@media (max-width: 768px) {
  .productionList {
    dt {
      h4 {
        color: #626061;
        font-size: 1.7vh;
        letter-spacing: 0.21vh;
      }
    }
    dd {
      color: #9c9b9b;
      font-size: 1.16vh;
      letter-spacing: 0.17vh;
      font-weight: normal;
    }
  }
}
@media (min-width: 768px) {
  .productionList {
    dt {
      h4 {
        color: #626061;
        font-size: 18px;
        letter-spacing: 0.05em;
      }
    }
    dd {
      color: #9c9b9b;
      font-size: 14px;
      letter-spacing: 0.05em;
      font-weight: normal;
    }
  }
}

@media (min-width: 1440px) {
  .productionList {
    dt {
      h4 {
        color: #626061;
        font-size: 24px;
        letter-spacing: 0.05em;
      }
    }
    dd {
      color: #9c9b9b;
      font-size: 20px;
      letter-spacing: 0.05em;
      font-weight: normal;
    }
  }
}

.tabbaroption {
  position: absolute;
  bottom: 35px;
  padding-bottom: 20px;
  height: auto;
  width: 100%;
  margin: 0;
  pointer-events: all;
  box-sizing: border-box;
  border: 2px solid #ffffff80;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-image: linear-gradient(#0000000f, #ffffff14);
  overflow: hidden;
  animation: upProduction 1s;
}
@keyframes upProduction {
  0% {
    bottom: -135px;
  }
  100% {
    bottom: 35px;
  }
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.5);
  background-image: linear-gradient(#0000000f, #ffffff14); */
  background: url(../assets/options/mask.png);
  /* filter: blur(1px); */
  opacity: 0.75;
}
.tabbaroption hr {
  margin: 0px;
  width: calc(100% - 42px);
  height: 1px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  box-sizing: border-box;
  border: none;
}
.topbox {
  position: relative;
  top: 0;
  height: auto;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 auto;
}

.search {
  position: relative;
  top: 0px;
  margin: 6px 0px;
  opacity: 1;
  z-index: 1;
}
.inputed {
  /* background-color: #fff; */
  border-radius: 20px;
  border: 2.5px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAFKUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANWc2JIAAABtdFJOUwAc+uCw/uj9Ww/D1QZZXfJ7pZBK5q6nrL/AGdSo+bj2TGrQM42XyTgoNfzbB8pFxCYK7+f7loAnsvDFgi2FqTLf2iHjcuLGIARwrfQDQqK2qjq3Nt0SPMyTz8ERASuSCTCayJkuPU2RpNOzzQwQTMMIAAABTElEQVQ4y82U1XPCQBCHjwAJLi1QKO5eoO7u7u5uv///tQnM0LvLdegj+3Tf5ptsbmc3hHR5pGdCvujwUHB0+k9l4AztCB0IlW+v9tDoH8mO+7TTeUXvGD4AKXvTgqNdM3Bp5R2rHfgq/HKxDAQdnPQOfDboxFUOyLDOK1BtsKmTY5jTdOL6AtE6/wWnwB7NeeBNfxcTsEZhBqgL+gZsUDiJBUHn1rfQR+EEAqL+riJMEdAjkkxY+o+0QpHMFG9HFSmKbJgXOI44IhROAc96KQEkKZwDHvRSDKV+wtQr3fNOLzDLJF4AOzc+BhlSgU09ATXGMqgD5uHeffcIlPNULVmdYJduNGuAeX9nu3n3RKy5DYqFtyq36lzDbfObcnH1IHlcisAixcP2RimRMUIsQossLm+GjcZUINnqj2Y5O260ark7771z0Nutv6Qfm0RJlZP5AFEAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: 96% center;
  background-size: auto 50%;
  height: 20px;
  width: 200px;
  outline: none;
  text-indent: 6px;
  background-color: #f5f6f8;
  font-size: 12px;
  color: grey;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.selector {
  position: relative;
  border: none;
  top: 0px;
  width: 100%;
  height: auto;
  margin: 0px 0px 20px;
  user-select: none;
}

.direction {
  background: transparent;
  width: auto;
  height: 32px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.direction > img {
  height: 15px;
  width: auto;
}
.selected {
  padding: 0px;
  width: 88%;
}
.litseletor {
  background-color: transparent;
  list-style-type: none;
  display: flex;
  padding: 0px;
  margin: -7px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 13px 0px;
  overflow-y: scroll;
}
.litseletor1 {
  justify-content: space-around;
  padding: 7px 0;
}
.litseletor1 li {
  position: relative;
  bottom: 100px;
}
.litseletor1 li:nth-child(1) {
  animation: toY 1s ease forwards;
}
.litseletor1 li:nth-child(2) {
  animation: toY 1s ease 0.3s forwards;
}
.litseletor1 li:nth-child(3) {
  animation: toY 1s ease 0.6s forwards;
}
@keyframes toY {
  0% {
    bottom: -100px;
  }
  100% {
    bottom: 0;
  }
}
.litseletor::-webkit-scrollbar {
  display: none;
}
.slide {
  height: 58px;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}
.color-selected {
  // margin-right: 9px;
  /* padding: 4px; */
  width: auto;
  height: 46px;
  /* border:1px solid blue; */
  border-radius: 15px;
  cursor: pointer;
  /* 判断 */
  /* background:rgb(0 0 0 / 10%); */
}
.color-selected img {
  height: 46px;
  width: auto;
  /* border:1px solid red; */
  pointer-events: none;
}
.color-selected1 {
  margin-right: 9px;
  /* padding: 4px; */
  width: auto;
  height: 40px;
  /* border:1px solid blue; */
  border-radius: 15px;
  cursor: pointer;
  /* 判断 */
  /* background:rgb(0 0 0 / 10%); */
  /* border:1px solid blue; */
}
.plannings {
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  color: #403c39;
  opacity: 0.3;
  display: flex;
  align-content: center;
  margin: 10px;
  justify-content: center;
  /* border:1px solid red; */
}
.updata_fontcolor {
  opacity: 1;
}
#prepng {
  opacity: 0.2;
}
#nextpng {
  opacity: 0.2;
}
#prepng2 {
  opacity: 0.2;
}
#nextpng2 {
  opacity: 0.2;
}
</style>