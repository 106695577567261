import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from "../views/Home.vue"
import shoucang from "../views/shoucang.vue"
import ls from "../views/Lishi.vue"
import login from "../views/Login.vue"
import userinfo from "../views/userinfo.vue"
import editNickName from "../views/editNickName.vue"
import concat from "../views/concat.vue"
import Reg from "../views/Reg.vue"
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/Reg',
    name: 'Reg',
    component: Reg
  },
  {
    path: '/sc',
    name: 'shoucang',
    component: shoucang
  },
  {
    path: '/ls',
    name: 'ls',
    component: ls
  }
  ,
  {
    path: '/info',
    name: 'userinfols',
    component: userinfo
  }
  ,
  {
    path: '/editNickName',
    name: 'editNickName',
    component: editNickName
  }
  ,
  {
    path: '/concat',
    name: 'concat',
    component: concat
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};
export default router
