<template>
    <div class="user-view">


        <div class="page-header" v-if="userInfo">
            <img :src="userInfo.avatar" style="   ;border-radius: 50%;" width="64rpx" height="64rpx" @click="toInfo" />

            <div class="login-text">{{ userInfo.nikeName }} <span></span></div>
        </div>
        <div class="page-header" v-else>
            <img src="../assets/user/head-img.png" style="   ;border-radius: 50%;" width="64rpx" height="64rpx" />

            <div class="login-text" @click="toLogin">{{ $t("clickToLogin") }} <span><img src="../assets/user/right.png"
                        style="width: 6px; height: 10px;" alt=""></span></div>
        </div>

        <div class="tabs flex">
            <div class="tabs-item" @click="toSc">
                <span style="vertical-align: middle; margin-right: 20px;"><img src="../assets/user/sc.png" alt=""
                        width="16rpx" height="16rpx"></span> {{ $t("shoucang") }}/{{ userInfo ? userInfo.collectNum : '**' }}
            </div>
            <div class="tabs-item" @click="toLs">
                <span style="vertical-align: middle; margin-right: 20px;"><img src="../assets/user/jl.png" alt=""
                        width="16rpx" height="16rpx"></span> {{ $t("lishi") }}/{{ userInfo ? userInfo.hisNum : '**' }}
            </div>
        </div>

        <div class="info-list">
            <div class="  flex list-item border-b" @click="toInfo">
                <div>{{ $t("userinfo") }}</div>
                <div class="flex-end"><span><img src="../assets/user/right.png" style="width: 10px; height: 16px;"
                            alt=""></span></div>
            </div>
            <div class="title flex list-item border-b" @click="toBindPhone">
                <div>{{ $t("bindPhone") }}</div>
                <div class="flex-end" style="font-size: 12px; color: #8D8D8D;">{{ (userInfo && userInfo.phone) ?
                    userInfo.phone : $t("bindPhone") }}
                    <span style="vertical-align: middle; margin-left: 10px;"><img src="../assets/user/right.png"
                            style="width: 6px; height: 10px;" alt=""></span>
                </div>
            </div>
            <div class="title flex list-item border-b" @click="toBindEmail">
                <div>{{ $t("bindEmail") }}</div>
                <div class="flex-end" style="font-size: 12px; color: #8D8D8D;">{{ (userInfo && userInfo.email) ?
                    userInfo.email : $t("bindEmail") }}
                    <span style="vertical-align: middle; margin-left: 10px;"><img src="../assets/user/right.png"
                            style="width: 6px; height: 10px;" alt=""></span>
                </div>
            </div>

            <div class="title flex list-item" @click="editArea">
                <div>{{ $t("area") }}</div>
                <div class="flex-end" style="font-size: 12px; color: #8D8D8D;">{{ (userInfo && userInfo.countryName) ?
                    userInfo.countryName +"-"+userInfo.areaName : $t("area") }}
                    <span style="vertical-align: middle; margin-left: 10px;"><img src="../assets/user/right.png"
                            style="width: 6px; height: 10px;" alt=""></span>
                </div>
            </div>


        </div>

        <div class="info-list">
            <div class="  flex list-item  border-b" @click="toConcat">
                <div>{{ $t("aboutUs") }}</div>
                <div class="flex-end"><span style="font-size: 12px; color: #8D8D8D;">v1.01</span><span
                        style="vertical-align: middle; margin-left: 10px;"><img src="../assets/user/right.png"
                            style="width: 6px; height: 10px;" alt=""></span></div>
            </div>
            <div class="  flex list-item " @click="doLogOut">
                <div>{{ $t("logOut") }}</div>
                <div class="flex-end"><span style="font-size: 12px; color: #8D8D8D;"></span><span
                        style="vertical-align: middle; margin-left: 10px;"><img src="../assets/user/right.png"
                            style="width: 6px; height: 10px;" alt=""></span></div>
            </div>

        </div>


        <div class="user-footer">
            <div style="color: #3B3B3B;">
                @optimal collection
            </div>

            <div style=" color: #979797;font-size: 12px;margin-top: 10px; ">
                <span @click="toConcat(1)">《{{ $t("xieyi") }}》</span>
                <!--div>
                        版本V1.1

                    </div-->

            </div>



        </div>

        <div class="Version" v-if="logoutStatus"  @click.self="logoutStatus = false" >
        <div class="Version-container" >
            <!-- 提示图片 -->
            <div>
                <div style="line-height: 20px;">
                    <img src="../assets/gth.png" alt="" style="width: 20px; height: 20px;vertical-align: middle;">
                    <span style="vertical-align: middle; padding-left: 10px;color:#205BA0; font-weight: 700;">{{ $t('querentuichu') }}</span>

                </div>

                

                <div style="padding-left: 30px;" class="article-content">
                    <p class="article" >
                        {{ $t('isquerentuichu') }}
                    </p>
                    
                </div>
 


            </div>
            <!-- 关闭按钮 -->
            <div class="close">
                <span class="closeBtn"  @click="doLoginOut" >{{ $t('queding') }}</span>
                <span class="closeBtn1"   @click="logoutStatus = false" >{{ $t('quxiao') }}</span>
            </div>
        </div>
</div>
    </div>
</template>
  
<script>
//import func from 'vue-editor-bridge';
export default {
    name: "Member",
    data() {
        return {
            userInfo: null,
            textData: {},
            textEN: {
                toLogin: 'Click to log in to account',
                shoucang: 'Collection',
                lishi: 'History',
                userinfo: "Personal Information",
                phone: 'Bind phone number',
                email: 'Bind email',
                about: 'About Us',
                xieyi: "User Agreement and Privacy Policy",
                zhengce: 'Privacy Policy'
            },
            textCN: {
                toLogin: '点击登录账号',
                shoucang: '收藏',
                lishi: '历史记录',
                userinfo: "个人信息",
                phone: '绑定手机号码',
                email: '绑定邮箱',
                about: '关于我们',
                xieyi: "用户协议和隐私政策",
                zhengce: '',

            },
            logoutStatus:false
        };
    },
    props: ['languageType'],
    computed: {
        guide() {
            return this.$store.state.guide
        },


    },
    watch: {
        guide(vo) {
            this.$emit('showUser')
            if (vo == 'icon-3') {
                this.getUserInfo()
            }
        },

    },
    methods: {
        setlanguage(e) {
            console.log(e)
            if (e == 'en') {
                this.textData = this.textEN
            } else {
                this.textData = this.textCN
            }
        },
        getUserInfo(e) {
            ///jeecg-boot/users/api/get/user
            this.logoutStatus = false
            let token = localStorage.getItem('token')
            let _this = this;
            this.$ajax({
                method: "post",
                url: _this.$global.serverSrc + "/jeecg-boot/users/api/get/user",
                header: { "X-Access-Token": token }
            }).then((res) => {
                console.log(res)
                if (res.data.code == 200) {
                    _this.userInfo = res.data.result
                } else {
                    _this.userInfo = null
                }
            }).catch(e => {
                _this.userInfo = null
            })

        },
        toReg() {
            this.$emit('Reg');
        },
        toLogin() {
            this.$emit('toLogin')
        },
        toSc() {
            if (this.userInfo) {
                this.$emit('showShoucang')
            }

        },
        toLs() {
            if (this.userInfo) {
                this.$emit('showLishi')
            }

        },
        toInfo() {
            if (this.userInfo) {
                this.$emit('showInfo')
            }

        },
        toConcat(e) {

            this.$emit('showAboutUs', e)
        },
        toBindPhone() {
            if (this.userInfo) {
                this.$emit('showBindPhone')
            }

        },
        toBindEmail() {
            if (this.userInfo) {
                console.log('emial')
                this.$emit('showBindEmail')
            }

        },
        editArea() {
            if (this.userInfo) {
                this.$emit('editArea')
            }
        },
        doLogOut() {
            this.logoutStatus = true
           
           
        },
        doLoginOut(){
            this.logoutStatus = false
            localStorage.removeItem('token')
            this.toLogin()
        }

    }

};
</script>
  
<style scoped>
.user-view {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    position: absolute;
    z-index: 9;
    overflow: scroll;
    pointer-events: all;
}

.page-header {
    padding-top: 30px;
    text-align: center;
    width: 100%;


}

.login-text {
    font-size: 12px;
    line-height: 17px;
    color: #8d8d8d;
    margin-top: 20px;

}

.flex {
    display: flex;
}

.tabs {
    width: 90%;
    margin-top: 20px;
    margin: 30px auto;
}

.tabs .tabs-item {
    width: 46%;
    margin: 0 2%;
    border: 1px solid;


    border-image-source: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
    border: 1px #fff solid;
    background-image: linear-gradient(180.21deg, rgba(255, 255, 255, 0.2) -9.27%, rgba(255, 255, 255, 0.6) 99.82%);
    border-radius: 14px;

    text-align: center;
    height: 64px;
    line-height: 64px;
    color: #3B3B3B;
    font-size: 14px;

}

.info-list {
    width: 88%;
    margin-left: 6%;
    margin-top: 20px;
    border-image-source: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
    border: 2px #fff solid;
    border-radius: 14px;
    background-image: linear-gradient(180.21deg, rgba(255, 255, 255, 0.2) -9.27%, rgba(255, 255, 255, 0.6) 99.82%);


}

.flex-end {
    margin-left: auto;
}

.list-item {
    margin: 20px;
}

.border-b {
    padding-bottom: 20px;
    border-bottom: 1px #fff solid;
}

.user-footer {
    margin-top: 50px;
    margin-bottom: 100px;
    width: 100%;
    text-align: center;
}
.Version {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 100;
    background-color:rgba(0, 0, 0, .5);
    transition:all 0.5s;
    top: 0;
}
.Version-container{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 450px;
    background-color: #f4f4f4;
    padding: 30px 20px 10px 20px ;
    box-sizing: border-box;
    box-shadow: -3px 3px 10px rgba(0, 0, 0, 0.3);
}

.close {
    color: #595757;
    font-size: 12px;
    padding-right: 20px;
    float: right;
    margin-top: 5px;

}

.close .closeBtn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #bfbfbf;
    color: #fff;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
}
.close .closeBtn1{
    display: inline-block;
    padding: 10px 20px;
    border: 1px #C0C0C0  solid;
    color: #C0C0C0;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
}
.article-content{
    
      border-bottom: 2px solid #bfbfbf;
      padding-bottom: 10px;
      background-color: #f4f4f4;
      margin-bottom: 8px;
}

.article{
    margin-top: 10px;
    font-size: 12px;
    line-height: 17px;
    padding-right: 20px;
    color: #575757;
    font-weight: normal;
} 


.article1{
    font-size: 12px;
    line-height: 17px;
    padding-right: 20px;
    color: #575757;
    font-weight: normal;
} 
</style>