<template>
  <div class="lishi"  >
    <div class="time-view" @click="visible=true">
      <div  >
        {{ searchTime?searchTime:$t('checkjilushijian')}}  
      </div>
      <div class="" style="margin-left: 10px;">
        <img src="../assets/user/dowm.png" width="16px" height="9px" alt=""> 

      </div>
     
    </div>
    <div class="ls-list infinite-scroll" @scroll="scrollBottom" >
      <div class="sc-item flex" v-for="(item) in list" @click="fuxian(item)" >
        <div class="img-view flex">
          <div class="ntColor" :style="{ backgroundColor: item.ntColor }"> </div>
          <div style=" margin-left: -10px; ">
            <div class="wtColor" :style="{ backgroundColor: item.wtColor }"></div>
          </div>
        </div>
        <div class="content-view">
          <div class="flex border-b">
            <div class="sc-title">
              <div class="cb9">{{$t('jilushijian')}}</div>
              <div>{{ item.createTime }}</div>
            </div>
            <div class="flex-end cb9 t12" style="padding-top: 10px;">
              {{$t('dianjichakan')}}
            </div>
          </div>

          <div class="ls-contnet">
            <div class="inside-info1">
              {{ item.ntText }}
            </div>
            <div class="outside-info1">
              {{ item.wtText }}
            </div>
          </div>

        </div>
      </div>



      <div style="width: 100%; height: 120px;"></div>
    </div>




    <div class="footer">
      <div class="login-btn" @click="goback">

        {{ $t("goBack") }}
      </div>
    </div>
    <PickerDate
            :visible.sync="visible"
            :initDate="searchTime"
            start="2023-06-12"
            end="2030-12-11"
            format="YYYY-MM-DD"
            title="日期选择器"
            cancelText="取消"
            confirmText=" 确定"
            :maskClick="false"
            @cancel="cancel"
            @confirm="confirm"
        />
  </div>
</template>
<script>
import 'vue-min-picker/lib/picker.css';//导入样式(建议全局导入)
import pickers from "vue-min-picker";
let {Picker,PickerDate,PickerRegion} = pickers;

export default {
  name: "App",
  data() {
    return {
      loadStatus: false,
      pageNo: 1,
      list: [],
      hasNext: true,
      loading: false,
      searchTime:'',
      visible:false
    }
  },
  components:{
        Picker,
        PickerDate,
        PickerRegion,
    },
  mounted() {
    window.addEventListener('scroll', this.scrollBottom)
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollBottom)
  }
  ,
  created() {

  },
  methods: {
    scrollBottom(e) {
      console.log(e)

      let conScrollHeight = e.target.scrollHeight   // 可以滚动区域的高度
        let conClientHeight = e.target.clientHeight   // 区域内容的高度
        let conScrollTop = e.target.scrollTop        //  内容滚动了的高度
        // 内容滚动了的高度 +  区域内容的高度 >= 可以滚动区域的高度
        // 则证明滑动到了页面底部，这个时候就去处理加载更多的逻辑
        console.log(conScrollHeight, conClientHeight, conScrollTop)
        if (conScrollTop + conClientHeight >= conScrollHeight-1)  {
          this.pageNo += 1
          this.getList()  //getList()获取后台数据
         }
      

    },
    goback() {
      this.$emit('showUser')
    },
    getList() {
      let _this = this;
      let data = {
        type: 1,
        pageNo: this.pageNo,
        pageSize: 20,
        date:this.searchTime
      }
      this.$ajax({
        method: "post",
        url: _this.$global.serverSrc + "/jeecg-boot/color/api/get/PflUserColl/list",

        data
      }).then((res) => {
        console.log(res)
        if (res.data.code == 200) {
          if(res.data.result.total>0){
            if(res.data.result.records.length>0){


            
                res.data.result.records.forEach((item)=>{
                  _this.list.push(item)
                })
                
              }
            }else{
              _this.hasNext = false
            }
          }else{
            _this.hasNext = false
            _this.list = []
          }
          
          

        
      })
    },
    fuxian(item) {
      this.$emit('fuxian', item.id)
    },
    cancel(){

},
confirm(e){
   this.pageNo= 1
   this.searchTime = e
   this.list = []
   this.getList()
},
  }
};
</script>
<style scoped>
.lishi {
  min-height: 100vh;
  width: 100%;
  background-color: #f0f0f0;
  position: absolute;
  z-index: 10;
  
  pointer-events: all;
}

.cb9 {
  color: #B9B9B9;
}

.time-view {
  margin: 20px;
  display: flex;
}

.ls-list {
  margin: 20px;
  width: 90%;
  margin: 0 5%;
  overflow-y: scroll;
  height: 100vh;
}

.ls-list::-webkit-scrollbar {
  width: 0;
}

.t12 {
  font-size: 12px;
}

.sc-item {
  margin-top: 10px;
  color: #3B3B3B;
  padding: 10px;
  border-radius: 14px;
  border: 1px #fff solid;
  background-image: linear-gradient(180.21deg, rgba(255, 255, 255, 0.2) -9.27%, rgba(255, 255, 255, 0.5) 60.82%);
}

.flex {
  display: flex;
}

.flex-end {
  margin-left: auto;
}

.img-view {
  width: 80px;
}

.content-view {
  width: calc(100% - 100px);
  padding-left: 20px;
}

.border-b {
  padding-bottom: 10px;
  border-bottom: 2px #fff solid;
}

.sc-title {
  font-size: 14px;
  letter-spacing: -0.3px;
  font-weight: 400;

}

.sc-title div {
  line-height: 20px;
}

.ls-contnet {
  margin: 10px 0;
}

.ls-contnet div {
  margin: 10px 0;
  color: #8D8D8D;
  font-size: 12px;
  letter-spacing: -0.3px;
  font-weight: 400;
}

.lishi .footer {
  width: 100%;
  padding: 20px 0;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
}

.lishi .footer .login-btn {
  width: 315px;
  border-radius: 6px;
  margin: auto;
  background-color: #335D9E;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  vertical-align: middle;
  line-height: 20px;
  padding: 10px;
}

.inside-info1 {
  font-size: 12px;
}

.ntColor {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);   
}
.wtColor{
  border-radius: 50%;
  height: 30px;
  width: 30px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}
</style>