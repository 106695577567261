const state = {
    insideId: 114,
    insideData: {},
    outsideId: 0,
    outsideData: {},
    currentSide: "outside",
    point: '',
    light: '',
    icon: '',
    product: '',
    scene: '',
    fromIcon: '',
    insideMaterial: '',
    outsideMaterial: '',
    languageType: 'en',
    guide:'icon-0',
    userinfo:{}
 
}
export default state