<template>
    <div class="user-view"  >
 
        
    </div>
  </template>
  
  <script>
  //import func from 'vue-editor-bridge';
  export default {
    name: "Member",
    data() {
      return {
          account:'',
          password:''
      };
    },
    onload(){
        this.wxLogin()
    },
    methods:{
        wxLogin() {
            if (this.is_weixin()) {
                const code = this.getUrlParam("code"); // 截取路径中的code
                if (code == null || code === "") {
                    let url = "";
                    let userAgent = navigator.userAgent;
                   
                    url = window.location.href;
                     
                    window.location.href =
                        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx046189744cd11ff0&redirect_uri=" +
                        encodeURIComponent(url) +
                        "&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect";
                }  

                if (code != "" && code != null) {
                    this.wxCode = code;
                    console.log(code)
                    this.getOpenid(code)
                }
            }
        },
        // 判断是否是微信浏览器
        is_weixin() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
        getUrlParam(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
            var r = window.location.search.substr(1).match(reg);//匹配目标参数
            if (r != null) return unescape(r[2]); return null; //返回参数值
        },
        getOpenid(code){
            this.$ajax({
                method: "get",
                url: _this.$global.serverSrc + "/jeecg-boot/appletLogin/api/gzh/auth?code="+code,
               
            }).then((res) => {
                if (res.data.code == 200) {
                    _this.$layer.msg("登录成功")
                    localStorage.setItem('token', res.data.result.token)
                    this.$router.replace({
                        query: {
                            ...this.$route.query,
                            code: "",
                        },
                        });
                    setTimeout(() => {
                        _this.$emit('showUser', res.data.result)
                    }, 1500);


                } else {
                    _this.$layer.msg(res.data.message)
                }
            })
        }

       
    }
   
  };
  </script>
   