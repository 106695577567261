<template>
    <div class="user-view"  >
 

        <div class="page-header">
               <img src="../assets/user/login-logo.png" width="173px" height="52px">

              
        </div>

         
        <div class="login-form">

            <div class="flex form-item">
                <div class="icon-view">
                    <img src="../assets/user/uname.png" width="16px" height="16px" alt="">
                </div>
                <div class="input-view">
                    <input type="text" v-model="account" class="form-input" :placeholder="$t('inputEmail')">
                </div>
            </div>

            <div class="flex form-item">
                <div class="icon-view">
                    <img src="../assets/user/pswd.png"  width="16px" height="16px" alt="">
                </div>
                <div class="input-view">
                    <input type="password" v-model="password" class="form-input"  :placeholder="$t('inputPsd')">
                </div>
            </div>
            <div class="flex form-item">
                <div class="icon-view">
                    <img src="../assets/user/pswd.png"  width="16px" height="16px" alt="">
                </div>
                <div class="input-view">
                    <input type="password" v-model="repassword" class="form-input"  :placeholder="$t('repsd')">
                </div>
            </div>


            <div class="flex form-item">
                <div class="icon-view">
                    <img src="../assets/user/pswd.png"  width="16px" height="16px" alt="">
                </div>
                <div class="input-view">
                    <input type="text" v-model="code" class="form-input"  :placeholder="$t('inputCode')" style="width:100px">
                </div>
                <div class="" style=" color:#838887;width: 120px;" @click="getCode">
                    {{ codeText }}
                </div>
            </div>

            <div class="flex form-item-btn" @click="doReg">
                 <div class="login-btn">

                    {{$t('reg')}}
                 </div>
            </div>
            <div style="margin-top: 20px; font-size: 12px; color:#838887 ">
                {{$t('hasAccount')}}！<span style=";text-decoration: underline" @click="toLogin">{{$t('toLogin')}}</span>
                        </div>

        </div>


        <!--div class="footer flex">
            <div style="padding-top:8px; padding-right: 5px;"> <input type="radio"></div>
            <div> 已阅读并同意 《用户协议》以及《隐私政策》。若您的手机号未注册，将为您直接注册账号</div>
           
           
        </div-->

      
        
    </div>
  </template>
  
  <script>
  //import func from 'vue-editor-bridge';

  export default {
    name: "Reg",
    data() {
      return {
        codeText:this.$t('getCode'),
        account:'',
        password:'',
        repassword:'',
        code:'',
        codeEmail:''
      };
    },
   
    methods:{
        toLogin(){
          this.$emit('toLogin')
       },

       getCode(){
       
            if(!this.validateEmail(this.account)){
                this.$layer.msg( "请正确输入邮箱地址")
                return
            }
            this.$ajax({
                method: "get",
                url:  _this.$global.serverSrc+"/jeecg-boot/appletLogin/api/emailSend?email="+this.account,
                }).then((res)=>{
                    _this.codeEmail = this.account
                })

       },
       
        validateEmail(email) {
        var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
        },
        doReg(){
            let _this = this
            if(!this.validateEmail(this.account)){
                this.$layer.msg( "请正确输入邮箱地址")
                return
            }
            if(this.password.length<6){
                this.$layer.msg( "密码最少需要6个字符")
                return
            }
            if(this.password != this.repassword){
                this.$layer.msg( "请确认密码输入是否正确")
                return
            }
            if(this.code.length!=6){
                this.$layer.msg( "请正确输入验证码")
                return
            }
            let data = {
                account:this.account,
                password:this.password,
                captcha:this.code
            }

            this.$ajax({
                method: "post",
                url:  _this.$global.serverSrc+"/jeecg-boot/appletLogin/api/register",
                data
                }).then((res)=>{
                    console.log(res)
                    if(res.data.code==200){
                        _this.$layer.msg("注册成功")

                        setTimeout(() => {
                            _this.toLogin()
                        }, 1500);
                    }
                })

        }
       
    }
   
  };
  </script>
  
  <style scoped>
   .user-view{
       width: 100%;
       height:100%;
       background-color: #ffffff;
       position:absolute;
      z-index: 9;
      overflow: scroll;
      pointer-events: all;
   }
   .page-header{
      padding-top: 100px; 
      text-align:center;
       width: 100%;
       
      
   }
   .flex{
    display: flex;
}
   .login-form{
    width: 315px;
    margin: 100px auto;
   }
   .form-item-btn{
    margin-top: 80px;
    border-radius: 6px;
   }
   .login-form .form-item{
    background-color: #F5F8FF;
    padding: 14px;
    margin-top: 20px;
    border-radius: 6px;
   }

   .form-item .icon-view{
        width: 30px;
        text-align: center;
       padding-top: 3px;
   }

   .form-item .input-view{
        width: calc(100% - 30px);
   }
   .form-item .input-view .form-input{
    border: none;
    background-color:  #F5F8FF;;
  
    height: 20px;
   }
   .form-item .input-view .form-input:focus {
            border: none;
            outline: none;
            box-shadow: none;
        }
        .form-item .input-view .form-input::placeholder {  
        color: #A1A1A1;
        }
        .login-btn{
            width: 315px;
            border-radius: 6px;
            margin: auto;
            background-color: #335D9E;
            color: #ffffff;
            text-align: center;
            font-size: 14px;
            vertical-align: middle;
            line-height: 20px;
            padding: 12px;
        }
        .footer{
            font-size: 12px;
            letter-spacing: -0.3px;
            line-height: 16px;
            font-weight: 400;
            color: #838887;
            position: fixed;
            bottom: 80px;
            padding: 20px;
        }
  </style>