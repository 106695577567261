<template>
  <div class="home">
    <V3DApp ref="v3d" v-if="loadStatus" />
  </div>
</template>
<script>
// @ is an alias to /src
import V3DApp from "../components/V3DApp.vue";
/*import insideData from '../assets/colorData/insideData.json'
import outsideData from '../assets/colorData/outsideData.json'
import keywordsData from '../assets/colorData/keywordsData.json'
import sacolorData from '../assets/colorData/sacolorData.json'*/
import insideData from '../../public/static/colorData/insideData.json'
import outsideData from '../../public/static/colorData/outsideData0329.json'
import keywordsData from '../../public/static/colorData/keywordsData.json'
import sacolorData from '../../public/static/colorData/sacolorData.json'

export default {
  name: "App",
  data() {
    return {
      loadStatus: false,
      inStatus: false,
      outStatus: false,
      inid: "",
      outid: ""
    }
  },
  components: {
    V3DApp,
  },
  methods: {
    downsort(arr = [], key) {
      let tmp = JSON.parse(JSON.stringify(arr)); //深拷贝一份
      tmp.sort(function (a, b) {
        //console.log('a',a)
        a = a[key];
        b = b[key];
        return b - a;
      });

      return tmp;
    },
    chineseCharacters(property, sort) {
      return function (a, b) {
        var value1 = a[property].charAt(0);
        var value2 = b[property].charAt(0);
        if (sort == 'asc') {
          return value1.localeCompare(value2);
        } else {
          return value2.localeCompare(value1);
        }

      }
    }

  },
  mounted: function () { },
  created() {





    //初始化数据
    let insideId = this.getUrlKey("inside") ? this.getUrlKey("inside") : 0;
    let outsideId = this.getUrlKey("outside") ? this.getUrlKey("outside") : 218;
    let point = this.getUrlKey("SimPoint") ? this.getUrlKey("SimPoint") : -1;
    let light = this.getUrlKey("SimLight") ? this.getUrlKey("SimLight") : -1;
    let product = this.getUrlKey("product")
      ? this.getUrlKey("product")
      : "Product_0";
    let scene = this.getUrlKey("scene") ? this.getUrlKey("scene") : "Scene_0";
    let guide = this.getUrlKey("guide") ? this.getUrlKey("guide") : 0;
    let dbclick = this.getUrlKey("dbclick") ? this.getUrlKey("dbclick") : 0;
    let code = this.getUrlKey("code") ? this.getUrlKey("code") : '';
    let inID = this.getUrlKey("inID") ? this.getUrlKey("inID") : '';
    let outID = this.getUrlKey("outID") ? this.getUrlKey("outID") : '';
    this.$store.commit("setInsideId", insideId);
    this.$store.commit("setOutsideId", outsideId);
    this.$store.commit("setPoint", point);
    this.$store.commit("setLight", light);
    this.$store.commit("setProduct", product);
    this.$store.commit("setScene", scene);


    //初始化 URL
    if (!code) {
   
      if (!inID && !outID) {

        this.$router.push({
          path: "/",
          query: {
            inside: insideId,
            outside: outsideId,
            SimPoint: point,
            SimLight: light,
            product: product,
            scene: scene,
            guide,
            dbclick,

          },
        });
      } else {
        this.$router.replace({ query: { ...this.$route.query, inside: insideId } });
        this.$router.replace({ query: { ...this.$route.query, outside: insideId } });
        this.inid = inID
        this.outid = outID
      }

    } else {
      this.getOpenid(code)
    }

    this.getdefaultInData(0)
    this.getdefaultOutData(1)
    this.getSaData()
    this.getChuangxinType()

    let inbase =  {
        "Material": "CERAMICAL_Inside",
        "Light": "1.5",
        "Value": [0.1, 0.4, 0.05, 1.5],
        "Size": [15, 0, 0],
        "Switch": [0, 0, 0],
        "Color": ["#FFD061", "#FF9313", "#4F4F4F", "#000000", "#FFFFFF", "#000000"],
        "keyword": "黄橙棕",
        "recommend": [224, 121, 49, 55],
        "aliasName": "",
        "SwitchAO": 1,
        "textCN": ["CERAMICL", "内涂系列", "C8445-8P096/9172", "黄铜色"],
        "textEN": ["CERAMICL", "Interior", "C8445-8P096/9172", "Brass"],
        "id": 112,
        "colorCode": "C8445-8P096/9172"
      }

      let outBase =   {
        "Material": "SILIMAX_Outside",
        "MaterialId": 19,
        "Light": "1.5",
        "Value": [0, 0.1, 0.2, 0, 1, 1.5],
        "Size": [0, 0, 0],
        "Switch": [0, 0, 0],
        "Color": ["#DCDBD8", "#303030", "#FFFFFF", "#E7E3DF", "#FFFFFF", "#000000"],
        "colorCode": "A5500-21920",
        "SwitchAO": 1,
        "keyword": "黑白灰",
        "textCN": ["SILIMAX", "外涂系列", "A5500-21920", "交通白"],
        "textEN": ["SILIMAX", "Exterior", "A5500-21920", "Traffic White"],
        "id": 218,
        "dataId": 0,
        "recommend": [],
        "aliasName": ""
      }

    localStorage.setItem("baseInsideData" , JSON.stringify(inbase))

    localStorage.setItem("baseOutsideData" , JSON.stringify(outBase))

  },
  methods: {
    getOpenid(code) {
      let _this = this
      this.$ajax({
        method: "get",
        url: _this.$global.serverSrc + "/jeecg-boot/appletLogin/api/gzh/auth?code=" + code,

      }).then((res) => {
        if (res.data.code == 200) {

          localStorage.setItem('token', res.data.result.token)
          let params = Object.assign({},this.$route.query)
            delete params.code
            this.$router.push({
              path: "/",
              query: params,
            });


        } else {
          _this.$layer.msg(res.data.message)
        }
      })
    },
    getUrlKey(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },

    getSaData() {
      console.log(sacolorData)
      localStorage.setItem('saColorList', sacolorData)
      /*let _this = this
    
      this.$ajax({
        method: "post",
        url: _this.$global.serverSrc + "/jeecg-boot/color/api/get/pflDot/list",
      }).then((res) => {
        if (res.data.code == 200) {

          var list = res.data.result;
          var saColorList = []
          list.forEach((item) => {
            saColorList.push(item.colorValue)
          });
          localStorage.setItem('saColorList', saColorList)
        }
      })*/

    },

    //获取创新产品

    getChuangxinType() {

      this.$ajax({
        method: "post",
        url: this.$global.serverSrc + "/jeecg-boot/color/api/get/PflInnovateType/list",
      }).then((res) => {
        console.log(res)
        if (res.data.code == 200) {
          let arr = []
          res.data.result.forEach((item , index)=>{
              item.id = index
              arr.push(item)
          })

          localStorage.setItem('chuangxinType', JSON.stringify(arr))
        }
      })
    },

    getdefaultInData(type = 0) {
      let _this = this
      let data = {
        aroundType: type,
        pageNo: 1,
        pageSize: 30,
        id: this.inid
      }
      this.$ajax({
        method: "post",
        url: _this.$global.serverSrc + "/jeecg-boot/color/api/selectColor",
        data
      }).then((res) => {
        console.log(res)
        if (res.data.code == 200) {

          var insideList = []




          res.data.result.records.forEach(function (item) {
            var insideItem = {}
            if (item.colorJson) {
              var jsonData = JSON.parse(item.colorJson)

              insideItem.Material = jsonData.Material
              insideItem.MaterialId = item.materialId
              insideItem.Light = jsonData.Light
              insideItem.Value = jsonData.Value
              insideItem.Size = jsonData.Size
              insideItem.Switch = jsonData.Switch
              insideItem.Color = jsonData.Color
              insideItem.keyword = item.keyWord
              insideItem.recommend = item.valueIds != "" ? item.valueIds.split(',') : [200, 197, 64]
              insideItem.dataId = item.id
              insideItem.colorCode = item.colorCode
              insideItem.aliasName = item.aliasName
              insideItem.SwitchAO = item.SwitchAO ? 1 : 0

              insideItem.typeId = item.typeId
              insideItem.Material = insideItem.Material
              insideItem.textCN = [item.materialId_dictText, "内涂系列", item.colorCode, item.znName]
              insideItem.textEN = [item.materialId_dictText, "Interior", item.colorCode, item.enName]
              insideItem.id = insideList.length
              insideList.push(insideItem)

            }
          })
          localStorage.setItem('insideData', JSON.stringify(insideList))

        
          // localStorage.setItem('insideData' ,JSON.stringify(insideData) )
          //  localStorage.setItem('outsideData', JSON.stringify(outsideList))
          this.inStatus = true
          if (this.outStatus) {
            this.loadStatus = true

            let params = Object.assign({},this.$route.query)
            delete params.inID
            delete params.outID
            params.inside = 0
            params.outside = 0
            this.$router.push({
              path: "/",
              query: params,
            });

          }


        }
      })
    },
    getdefaultOutData(type = 0) {
      let _this = this
      let data = {
        aroundType: type,
        pageNo: 1,
        pageSize: 30,
        id: this.outid
      }
      this.$ajax({
        method: "post",
        url: _this.$global.serverSrc + "/jeecg-boot/color/api/selectColor",
        data
      }).then((res) => {
        console.log(res)
        if (res.data.code == 200) {

          var outsideList = []




          res.data.result.records.forEach(function (item) {
            var insideItem = {}
            if (item.colorJson) {
              var jsonData = JSON.parse(item.colorJson)

              insideItem.Material = jsonData.Material
              insideItem.MaterialId = item.materialId
              insideItem.Light = jsonData.Light
              insideItem.Value = jsonData.Value
              insideItem.Size = jsonData.Size
              insideItem.Switch = jsonData.Switch
              insideItem.Color = jsonData.Color
              insideItem.keyword = item.keyWord
              insideItem.recommend = item.valueIds != "" ? item.valueIds.split(',') : [200, 197, 64]
              insideItem.dataId = item.id
              insideItem.colorCode = item.colorCode
              insideItem.aliasName = item.aliasName
              insideItem.SwitchAO = item.SwitchAO ? 1 : 0

              insideItem.typeId = item.typeId
              insideItem.Material = insideItem.Material
              insideItem.textCN = [item.materialId_dictText, "外涂系列", item.colorCode, item.znName]
              insideItem.textEN = [item.materialId_dictText, "Exterior", item.colorCode, item.enName]
              insideItem.id = outsideList.length
              outsideList.push(insideItem)


            }
          })

          localStorage.setItem('outsideData', JSON.stringify(outsideList))
         
          this.outStatus = true
          if (this.inStatus) {
            this.loadStatus = true
            let params = Object.assign({},this.$route.query)
            delete params.inID
            delete params.outID
            params.inside = 0
            params.outside = 0
            this.$router.push({
              path: "/",
              query: params,
            });
          }


        }
      })
    }
  }
};
</script>
<style >
.tabbaroption hr {
  margin: 0px;
  width: calc(100% - 42px);
  height: 1px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  box-sizing: border-box;
  border: none;
}

.topbox {
  position: relative;
  top: 0;
  height: auto;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
}

.search {
  position: relative;
  top: 0px;
  margin: 6px 0px;
  opacity: 1;
  z-index: 2;
}

.inputed {
  /* background-color: #fff; */
  border-radius: 20px;
  border: 2.5px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAFKUExURQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAANWc2JIAAABtdFJOUwAc+uCw/uj9Ww/D1QZZXfJ7pZBK5q6nrL/AGdSo+bj2TGrQM42XyTgoNfzbB8pFxCYK7+f7loAnsvDFgi2FqTLf2iHjcuLGIARwrfQDQqK2qjq3Nt0SPMyTz8ERASuSCTCayJkuPU2RpNOzzQwQTMMIAAABTElEQVQ4y82U1XPCQBCHjwAJLi1QKO5eoO7u7u5uv///tQnM0LvLdegj+3Tf5ptsbmc3hHR5pGdCvujwUHB0+k9l4AztCB0IlW+v9tDoH8mO+7TTeUXvGD4AKXvTgqNdM3Bp5R2rHfgq/HKxDAQdnPQOfDboxFUOyLDOK1BtsKmTY5jTdOL6AtE6/wWnwB7NeeBNfxcTsEZhBqgL+gZsUDiJBUHn1rfQR+EEAqL+riJMEdAjkkxY+o+0QpHMFG9HFSmKbJgXOI44IhROAc96KQEkKZwDHvRSDKV+wtQr3fNOLzDLJF4AOzc+BhlSgU09ATXGMqgD5uHeffcIlPNULVmdYJduNGuAeX9nu3n3RKy5DYqFtyq36lzDbfObcnH1IHlcisAixcP2RimRMUIsQossLm+GjcZUINnqj2Y5O260ark7771z0Nutv6Qfm0RJlZP5AFEAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: 96% center;
  background-size: auto 50%;
  height: 20px;
  width: 200px;
  outline: none;
  text-indent: 6px;
  background-color: #f5f6f8;
  font-size: 12px;
  color: grey;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
</style>