module.exports = {
    "shoucang": "collect",
    "lishi": "history",
    "userinfo": "Personal Information",
    "bindPhone": "Bind Phone Number",
    "bindEmail": "Bind Email",
    "aboutUs": "About Us",
    "xieyi": "User Agreement and Privacy Policy",
    "editHeadImg": "Edit Avatar",
    "editNickname": "Edit Nickname",
    "goBack": "Go Back",
    "clickToLogin": "Click to log in",
    "inputEmail": "Please enter email",
    "inputPsd": "Please enter password",
    "inputPhone":'Please enter Phone Number',
    "repsd": "Confirm password",
    "toReg": "Register",
    "login": "Log in",
    "unLogin": "Not logged in yet",
    "other": "Log in with other methods",
    "readXy": "I have read and agree to the User Agreement and Privacy Policy. If your phone number is not registered, it will be registered for you directly",
    "inputCode": "Please enter verification code",
    "getCode": "Send Code",
    "reg": "Register an account",
    "hasAccount": "Already have an account",
    "toLogin": "Go to login",
    "serachTitle": "Search Prompt",
    "searchMsg1": " - Enter the second half of the model number, such as 54088 in W8634-54088, to select the corresponding color.",
    "searchMsg2": " - Enter Pantone color number, such as 110C, to select the corresponding color (same inside and outside). Note: Pantone color search is only applicable to the exterior painting.",
    "searchMsg3":' - Enter the Pantone TPG color code, such as 12-1106, to select the corresponding color (same color inside and outside). Note: Pantone color search is only applicable for external application.',
    "handTitle": "Operation Prompt",
    "handMsg2": " - Double-click on a color to achieve same inside and outside color; double-click on the color again to cancel same inside and outside color.",
    "searchErr": "Search failed",
    "searchSuccess": "Search successful",
    "save":'save',
    "guojia":'Country',
    "diqu":'City',
    "area":'Area',
    'logOut':'logOut',
    'Collectiontime':'Collection time',
    'checkCollectiontime':'Please select the collection time',
    'jilushijian':'Recording time',
    'checkjilushijian':'Please select the recording time',
    'dianjichakan':'Click to view',
    'querentuichu':'Confirm exit',
    'isquerentuichu':'Are you sure to exit the current account?',
    'queding':'confirm',
    'quxiao':'cancel'
    }