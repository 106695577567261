const mutaions = {
    setInsideId(state, val) {
        state.insideId = val
        // console.log(state)
    },
    setOutsideId(state, val) {
        state.outsideId = val
    },
    setInsideData(state, val) {
        state.insideData = val
    },
    setOutsideData(state, val) {
        state.outsideData = val
    },
    setPoint(state, val) {
        state.point = val
    },
    setLight(state, val) {
        state.light = val
    },
    setProduct(state, val) {
        state.product = val
    },
    setScene(state, val) {
        state.scene = val
    },
    setIcon(state, val) {
        state.icon = val
    },
    setFromIcon(state, val) {
        state.fromIcon = val
    },
    setInsideMaterial(state, val) {
        state.insideMaterial = val
    },
    setOutsideMaterial(state, val) {
        state.outsideMaterial = val
    },
    setLanguageType(state, val) {
        state.languageType = val
    },
    setCurrentSide(state, val) {
        state.currentSide = val
    },
 
    setGuide(state, val) {
        
        state.guide = val
    },
    setUserinfo(state, val) {
        console.log(val)
        state.userinfo = val
    },
}

export default mutaions