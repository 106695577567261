<template>
  <!-- <div class="colorselect"> -->
  <!-- <transition name="upshow1"> -->
  <div class="tabbaroption">




    <div class="mask"><!-- glass --></div>




    <div class="topbox">
      <div class="optionsLeft">
        <div class="inconect" id="SendImg" @click="screenshot">
          <!-- 收藏 -->
          <img src="../assets/options/shoucang.png" alt="" @click="scClick()" />
        </div>
        <!-- 流行色 -->

        <div class="hot" style="margin-right: 20px;" @click="tabClick('icon-0')">
          <img src="../assets/tabs1d.png" alt="" />
        </div>
        <div class="hot" @click="tabClick('icon-5')">
          <img src="../assets/tabs2.png" alt="" />
        </div>
      </div>
      <div class="search">
        <!-- 搜索组件 -->
        <div class="colorLine flex">

          <input type="range" class="range-dot" min="0" max="100" style="width: 100%;height: 0;margin-left: 0px;" step="1"
            @change="rangeChange">

        </div>
      </div>
      <!--div @click="doSearch" style="width: 16px;height: 16px;border-radius: 50%; background-color: #0F0504;">
       
      </div-->

    </div>
    <hr />
    <!-- 分割线 -->
    <div class="selector">
      <!-- 轮播图 -->
      <div class="slide">
        <!-- 左侧箭头 -->
        <div class="direction" @click.stop="clickArrow('inside', 'left')">
          <img src="../assets/options/pre.png" alt="" class="unEnd" />
        </div>
        <!-- 轮播图 -->
        <div class="swiperInside" @dblclick="dbclickInside">
          <swiper :options="swiperOption1" class="mySwiper3" ref="mySwiper3">
            <swiper-slide v-for="(inside_colors, index) in showInsideColor" :key="index" :class="`insideColorLi${index}`">
              <!-- {{ inside_colors.id }} -->
              <div class="colorSelected" :style="{
                backgroundColor: inside_colors['Color'][0],
              }">
                <div class="activeColorInside"></div>
                <img draggable="false" class="ocs-img" :src="maskList[inside_colors.MaterialId]" alt="" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="swiper-scrollbar1"></div>
        <!-- 右侧箭头 -->
        <div class="direction" @click.stop="clickArrow('inside', 'right')">
          <img src="../assets/options/next.png" alt="" class="unEnd" />
        </div>
      </div>

      <!-- 滑动选择行2 -->
      <div class="slide">
        <div class="direction" @click="clickArrow('outside', 'left')">
          <img src="../assets/options/pre.png" alt="" class="unEnd" />
        </div>
        <div class="swiperOutside" @dblclick="dbclickOutside">
          <swiper :options="swiperOption2" class="mySwiper4" ref="mySwiper4">
            <swiper-slide v-for="(outside_colors, index) in showOutsideColor" :key="index"
              :class="`outsideColorLi${outside_colors.id}`">
              <!-- {{ outside_colors.id }} -->
              <!-- <div class="gapline"></div> -->
              <div class="colorSelected" :style="{
                backgroundColor: outside_colors['Color'][0],
              }">
                <div class="activeColorOutside"></div>
                <img draggable="false" class="ocs-img" :src="maskImg" alt="" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="swiper-scrollbar2"></div>
        <div class="direction" @click="clickArrow('outside', 'right')">
          <img src="../assets/options/next.png" alt="" class="unEnd" />
        </div>
      </div>
    </div>
  </div>
  <!-- </transition> -->
  <!-- </div> -->
</template>

<script>
// swiper
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";

// 组件
import Search from "@/components/search.vue";
import { SetColor, LoadProduct } from "../Ledmodule/model.js";
export default {
  name: "Colorselect",
  components: {
    swiper,
    swiperSlide,
    Search,
  },
  props: [
    "insideColor",
    "outsideColor",
    "insideDbStatus",
    "outsideDbStatus",
 
    "currentInside",
    "currentOutside",
    "currentProduct",
    "languageType",
    "loadingFinish",
  ],
  data() {
    let _this = this;
    this.$nextTick(function () { });
    return {
      // 轮播图配置
      slideView: 5,
      searchStatus: false,

      keywordList: JSON.parse(localStorage.getItem('keywordList')),
      keyIndex: '2',
      maskImg: require("../assets/options/outside1.png"),
      maskList: [
        require("../assets/options/inside0.png"),
        require("../assets/options/inside1.png"),
        require("../assets/options/inside2.png"),
        require("../assets/options/inside3.png"),
        require("../assets/options/inside4.png"),
        require("../assets/options/inside5.png"),
        require("../assets/options/inside6.png"),
        require("../assets/options/inside7.png"),
      ],

      swiperOption1: {
        slidesPerView: 5,
        initialSlide: parseInt(this.getUrlKey("inside")),
        centeredSlides: true,
        slideToClickedSlide: true,
        //loop: true,
        observer: true,
        observeSlideChildren: true,
        observeParents: true,
        scrollbar: {
          el: ".swiper-scrollbar1",
          hide: true,
          snapOnRelease: true,
          dragSize: 30,
        },
        on: {
          // transitionEnd: function () {
          // console.log(this.realIndex);
          // console.log(_this.insidecolorselector_index);
          // console.log("end");
          // },
          
          slideChangeTransitionEnd: function () {
            // console.log("END");
            console.log('0288')
            if (_this.loadingFinish) {
              let id = this.realIndex

              // 改变内涂颜色
              /* _this.$ajax({
                 method: "post",
                 url:  _this.$global.serverSrc+"/app/color/save/color/statistics",
                 data:{id:_this.insideColor[id].dataId ,  mId:_this.insideColor[id].MaterialId}
                 })
                   _this.$ajax({
                 method: "post",
                 url:  _this.$global.serverSrc+"/app/color/save/material/statistics",
                 data:{id:_this.insideColor[id].MaterialId}
                 })*/


              if (_this.insideId == '') {
                _this.insideId = id
              } else {
                //_this.$refs.search.clearSearch();
                _this.insideId = ''
              }
              _this.changeColorInside(id);
              _this.$store.commit("setCurrentSide", "inside");
            }
          },
         


        },
      },
      swiperOption2: {
        slidesPerView: 5,
        initialSlide: parseInt(this.getUrlKey("outside")),
        centeredSlides: true,
        slideToClickedSlide: true,
        //loop: true,
        observer: true,
        observeSlideChildren: true,
        observeParents: true,
        scrollbar: {
          el: ".swiper-scrollbar2",
          hide: true,
          snapOnRelease: true,
          dragSize: 30,
        },
        on: {
          slideChangeTransitionEnd: function (e) {
            console.log(this.activeIndex)
            if (_this.loadingFinish) {

              let id = this.realIndex

              /* _this.$ajax({
               method: "post",
               url:  _this.$global.serverSrc+"/app/color/save/color/statistics",
               data:{id:_this.outsideColor[id].dataId , mId:_this.outsideColor[id].MaterialId}
               })
                _this.$ajax({
               method: "post",
               url:  _this.$global.serverSrc+"/app/color/save/material/statistics",
               data:{id:_this.outsideColor[id].MaterialId}
               })*/
              if (_this.outsideId == '') {
                _this.outsideId = id
              } else {
                //_this.$refs.search.clearSearch();
                _this.outsideId = ''
              }
              console.log(id)
              _this.changeColorOutside(id, "outside");
              _this.$store.commit("setCurrentSide", "outside");

            }
          },
        /*  doubleTap: function () {
            _this.$emit("outsideDb");
          },*/

        },
      },
      // 展示的内层颜色
      showInsideColor: [],

      // 展示的外层颜色
      showOutsideColor: [],
      //
      // languageType: "en",
      // 获取当前推荐色数据
      recommendData: [],
      // 当前处于色条搜索状态
      isFuzzy: false,
      fuzzyLength: [],
      changeInside: false,
      insideId: "",
      outsideId: '',
      range_value: 60,
      keywords: '',
      colorKeywords: '',
      insidecolorselector_index: parseInt(this.getUrlKey("inside")),
      outsidecolorselector_index: parseInt(this.getUrlKey("outside"))

    };
  },
  created() {
    // 将内外涂数据转化成数组
    /*   this.showInsideColor = this.objToArr(this.insideColor);
      this.showOutsideColor = this.objToArr(this.outsideColor); */
    /* this.showInsideColor = JSON.parse(localStorage.getItem('insideData'));
     this.showOutsideColor = JSON.parse(localStorage.getItem('outsideData'));
 
 
     // 文字描述
     this.sendLanguage(
       "inside",
       this.insideColor[this.insidecolorselector_index]
     );
     this.sendLanguage(
       "outside",
       this.outsideColor[this.outsidecolorselector_index]
     );*/
    this.initData()
  },
  mounted() {

  },
  computed: {
    guide() {
      return this.$store.state.guide
    }
  },
  watch: {
    guide(vo) {
      if (vo == 'icon-04') {
        let inside_index = parseInt(this.getUrlKey("inside"))
        let outside_index = parseInt(this.getUrlKey("outside"))

      
        let insideSwiper = this.$refs.mySwiper3.swiper;
      let outsideSwiper = this.$refs.mySwiper4.swiper;
      setTimeout(() => {
        insideSwiper.slideToLoop(inside_index);
        outsideSwiper.slideToLoop(outside_index);
      }, 200);
      
     
      }
    }
  },

  methods: {
    initData(type = "" , ctype) {
      console.log('asdasd')
      this.showInsideColor = JSON.parse(localStorage.getItem('insideData'));
      this.showOutsideColor = JSON.parse(localStorage.getItem('outsideData'));
      let inside_index = parseInt(this.getUrlKey("inside"))
      let outside_index = parseInt(this.getUrlKey("outside"))
      let insideSwiper = this.$refs.mySwiper3.swiper;
      let outsideSwiper = this.$refs.mySwiper4.swiper;
    


      if (type == 'fuxian') {
        let _this = this
        console.log(ctype,4)
        setTimeout(() => {
          //let ctype = _this.$store.getters.getCurrentSide
          if (ctype == 'inside') {
            insideSwiper.slideTo(0);
            _this.changeColorInside(0);
           
            _this.$store.commit("setCurrentSide", "inside");
          } else {
            outsideSwiper.slideTo(0);
            _this.changeColorOutside(0);
            _this.$store.commit("setCurrentSide", "outside");
          }
        }, 100);


      }else{
       setTimeout(() => {
          /*insideSwiper.slideToLoop(inside_index);
          outsideSwiper.slideToLoop(outside_index);*/
          insideSwiper.slideTo(inside_index);
          outsideSwiper.slideTo(outside_index);
        }, 200); 
        
        
      }
    },
    getUrlKey(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    rangeChange(e) {
      console.log(e.target.valueAsNumber)

      /*  let obj = {
          "0":"黑白灰",
          "10":"红粉色",
          "20":"红粉色",
          "30":"黄橙棕",
          "40":"黄橙棕",
          "50":"青绿色",
          "60":"青绿色",
          "70":"蓝紫色",
          "80":"蓝紫色",
          "90":"蓝紫色",
          "100":"蓝紫色",
        
        }*/

      this.searchFuzzy(Math.floor(e.target.valueAsNumber))
    },
    hexTohsl(hex) {
      let r = parseInt("0x" + hex.slice(1, 3)) / 255;
      let g = parseInt("0x" + hex.slice(3, 5)) / 255;
      let b = parseInt("0x" + hex.slice(5, 7)) / 255;

      var min = Math.min(r, g, b);
      var max = Math.max(r, g, b);
      var l = (min + max) / 2;
      var difference = max - min;
      var h, s, l;
      if (max == min) {
        h = 0;
        s = 0;
      } else {
        s = l > 0.5 ? difference / (2.0 - max - min) : difference / (max + min);
        switch (max) {
          case r:
            h = (g - b) / difference + (g < b ? 6 : 0);
            break;
          case g:
            h = 2.0 + (b - r) / difference;
            break;
          case b:
            h = 4.0 + (r - g) / difference;
            break;
        }
        h = Math.round(h * 60);
      }
      s = Math.round(s * 100); //转换成百分比的形式
      l = Math.round(l * 100);
      // s = s + s * 0.1;
      // l = l + l * 0.1;
      // s = s >= 100 ? 100 : s;
      // l = l >= 100 ? 100 : l;

      return `hsl(${h}deg ${s}% ${l}%)`;
    },
    changeShowHot() {
      this.$emit("changeShowHot", true);
    },
    dbclickInside() {
      this.$emit("insideDb");
    },
    dbclickOutside() {
      this.$emit("outsideDb");
    },
    tabClick(e) {

      this.$emit("tabClick", e);
    },
    // 点击箭头
    clickArrow(type, direction) {
      if (type === "inside") {
        // 获取内涂轮播图
        let insideSwiper = this.$refs.mySwiper3.swiper;
        if (direction === "left") {
          insideSwiper.slideToLoop(
            insideSwiper.activeIndex -   3
          );
        } else if (direction === "right") {
          insideSwiper.slideToLoop(
            insideSwiper.activeIndex   + 3
          );
        }
      } else {
        // 获取外涂轮播图
        let outsideSwiper = this.$refs.mySwiper4.swiper;
        if (direction === "left") {
          outsideSwiper.slideToLoop(
            outsideSwiper.activeIndex  - 3
          );
        } else {
          outsideSwiper.slideToLoop(
            outsideSwiper.activeIndex   + 3
          );
        }
      }
    },
    // 初始化搜索、
    initColorList() {
      console.log(111);
      let insideSwiper = this.$refs.mySwiper3.swiper;
      let outsideSwiper = this.$refs.mySwiper4.swiper;
      if (this.isFuzzy) {
        // 移除所有色条
        if (this.fuzzyLength.length > 0) {
          for (let i = 0; i < this.fuzzyLength[0]; i++) {
            insideSwiper.removeSlide(0);
          }
          for (let i = 0; i < this.fuzzyLength[1]; i++) {
            outsideSwiper.removeSlide(0);
          }
        }
        setTimeout(() => {
          insideSwiper.slideToLoop(parseInt(this.insidecolorselector_index));
          outsideSwiper.slideToLoop(parseInt(this.outsidecolorselector_index));
        }, 0);
        this.fuzzyLength = [];
        this.isFuzzy = false;
      }
    },
    searchEmpty() {
      console.log('22222222222')
      this.$emit(
        "searchEmpty",
      );
    },

    searchAccurateConfirm(index, type) {
      let msg = "已成功搜索到内涂颜色：";
      let color = ""
      let colors = []
      if (type == 'outside') {
        msg = '已成功搜索到外涂颜色：'
        color = this.outsideColor[index]

        colors = [this.insideColor[this.insidecolorselector_index], color]

      } else {
        color = this.insideColor[index]
        colors = [color, this.outsideColor[this.outsidecolorselector_index]]
      }

      msg = msg + color.colorCode
      console.log(msg)
      this.$emit(
        "searchSuccess",
        msg,
        colors,
        index, type
      );
    },

    searchTypeChange(e) {
      console.log('123')
      let _this = this
      _this.searchType1 = e


    },

    // 精准搜索
    searchAccurate(index, type) {
      console.log(index, type)
      this.searchStatus = true;
      let _this = this
      console.log(type == "inside")
      if (type == "inside") {
        // 获取内涂轮播图
        let insideSwiper = this.$refs.mySwiper3.swiper;

        insideSwiper.slideToLoop(parseInt(index));

      } else {
        let outsideSwiper = this.$refs.mySwiper4.swiper;
        // 获取外涂轮播图
        this.deleteRecommend();
        setTimeout(() => {
          outsideSwiper.slideToLoop(parseInt(index));
          /*  setTimeout(function(){
              _this.$emit("outsideDb",'search');
            },500)*/

        }, 0);
      }
    },
    // 删除外涂推荐dom
    deleteRecommend() {
      let outsideSwiper = this.$refs.mySwiper4.swiper;
      if (this.recommendData.length > 0) {
        for (let i = 0; i < this.recommendData[1]; i++) {
          outsideSwiper.removeSlide(this.recommendData[0]);
        }
        this.recommendData = [];
      }
    },
    // 色条搜索状态
    /*
    searchFuzzy(inside, outside) {
      this.isFuzzy = true;
      let insideSwiper = this.$refs.mySwiper3.swiper;
      let outsideSwiper = this.$refs.mySwiper4.swiper;
      if (this.fuzzyLength.length > 0) {
        if (this.fuzzyLength[0] > 0) {
          for (let i = 0; i < this.fuzzyLength[0]; i++) {
            insideSwiper.removeSlide(0);
          }
        }
        if (this.fuzzyLength[1] > 0) {
          for (let i = 0; i < this.fuzzyLength[1]; i++) {
            outsideSwiper.removeSlide(0);
          }
        }
      }
      this.fuzzyLength = [inside.length, outside.length];
      // 删除外涂推荐色
      // this.deleteRecommend();
      // 内涂在前头添加色条中的数据

      setTimeout(() => {
        if (this.fuzzyLength[0] > 0) {
          let innerColor = this.slideAdd(inside, "inside");
          insideSwiper.addSlide(0, innerColor);
          insideSwiper.slideTo(1, 0);
          insideSwiper.slideToLoop(2, 1000);
        }
        // 外涂在前头添加色条中的数据
        if (this.fuzzyLength[1] > 0) {
          let innerColor2 = this.slideAdd(outside, "outside");
          outsideSwiper.addSlide(0, innerColor2);
          outsideSwiper.slideTo(1, 0);
          outsideSwiper.slideToLoop(2, 1000);
        }
      }, 0);
    },
    */
    doSearch(){
      let type =  this.$store.getters.getCurrentSide
      this.$emit('getMoreData', type == 'inside' ? 0 : 1,"", true,'黑白灰')
    },
    searchFuzzy(searchColor, type = 'inside', index = 0) {
      this.keyIndex = index
      type = this.$store.getters.getCurrentSide
      console.log(type)
      this.isFuzzy = true;
      let insideSwiper = this.$refs.mySwiper3.swiper;
      let outsideSwiper = this.$refs.mySwiper4.swiper;
      this.keywords = searchColor
      this.$emit('getMoreData', type == 'inside' ? 0 : 1, searchColor, true)

      return


      console.log(searchColor)
      var keywordList = {
        '红粉色': {
          insideNum: 65,
          outsideNum: 283
        },
        '蓝紫色': {
          insideNum: 213,
          outsideNum: 1865
        },
        '黄橙棕': {
          insideNum: 113,
          outsideNum: 777
        },
        '黑白灰': {
          insideNum: 0,
          outsideNum: 0
        },
        '青绿色': {
          insideNum: 270,
          outsideNum: 2947
        },
      }

      setTimeout(() => {
        if (type == 'inside') {
          insideSwiper.slideTo(keywordList[searchColor].insideNum, 0);
          insideSwiper.slideToLoop(keywordList[searchColor].insideNum, 1000);
        } else {
          outsideSwiper.slideTo(keywordList[searchColor].outsideNum, 0);
          outsideSwiper.slideToLoop(keywordList[searchColor].outsideNum, 1000);
        }


      }, 0);
    },
    // 色条搜索相同
    searchSameFuzzy(type) {
      let insideSwiper = this.$refs.mySwiper3.swiper;
      let outsideSwiper = this.$refs.mySwiper4.swiper;
      if (type === "inside") {
        insideSwiper.slideToLoop(2, 1000);
      } else if (type === "outside") {
        outsideSwiper.slideToLoop(2, 1000);
      }
    },
    // 添加dom元素
    slideAdd(addArr, type) {

      let innerColor = "";
      if (type === "outside") {
        for (let i = 0; i < addArr.length; i++) {
          innerColor =
            innerColor +
            `<div class="swiper-slide outsideColorLi${addArr[i]}">
                <div
                  class="colorSelected"
                  style="
                    background-color: ${this.outsideColor[addArr[i]].Color[0]}
                  "
                >
                  <div class="activeColorOutside"></div>
                    <img
                      draggable="false"
                      class="ocs-img"
                      src="${this.maskImg}"
                      alt=""
                    />
                </div>
              </div>`;
        }
        return innerColor;
      } else if (type === "inside") {
        for (let i = 0; i < addArr.length; i++) {
          innerColor =
            innerColor +
            `<div class="swiper-slide insideColorLi${addArr[i]}">
                    <div
                      class="colorSelected"
                      style="
                        background-color: ${this.insideColor[addArr[i]].Color[0]
            }
                      "
                    >
                      <div class="activeColorInside"></div>
                        <img
                          draggable="false"
                          class="ocs-img"
                          src="${this.maskList[
            this.insideColor[addArr[i]].MaterialId
            ]
            }"
                          alt=""
                        />
                    </div>
                  </div>`;
        }
        return innerColor;
      }
    },
    // 改变语言
    sendLanguage(type, data) {
      if (data) {
        // 内涂
        if (type === "inside") {
          // 英语
          if (this.languageType === "en") {
            this.$emit("showInsideInfo", data[`textEN`]);
          } else {
            this.$emit("showInsideInfo", data[`textCN`]);
          }
        } else {
          if (this.languageType === "en") {
            this.$emit("showOutsideInfo", data[`textEN`]);
          } else {
            this.$emit("showOutsideInfo", data[`textCN`]);
          }
        }
      }

    },
    // 改变内涂颜色
    changeColorInside(index) {
      let _this = this
      if (this.outsideDbStatus == 1) return;
     
      console.log('2222')
      // 保存外涂编号
      this.$emit("changeColorIndex", "inside", index);
      let insideData = this.insideColor[index];
      // 改变模型外涂颜色
      if (this.searchStatus) {
        console.log('3333')
        _this.$emit("insideDb", 'search');

        this.searchStatus = false
      } else {
        console.log('4444')
        if( parseInt(this.getUrlKey("dbclick"))==1){
          SetColor(insideData, "inside", "dbclick", "", this.currentProduct);
        }else{
          SetColor(insideData, "inside", "click", "", this.currentProduct);
        }

       
        _this.$emit('addLishi')
      }

      // 改变外涂文字描述
      this.sendLanguage("inside", insideData);
      this.$store.commit("setCurrentSide", "inside");

      if (index >= this.insideColor.length - 10) {
        _this.$emit("getMoreData", 0);
      }
    },
    changeColor(index1, index2) {
      let insideSwiper = this.$refs.mySwiper3.swiper;
      let outsideSwiper = this.$refs.mySwiper4.swiper;
      if (index1 >= 0) {
        // 判断是否处于模糊搜索
        if (this.isFuzzy) {
          insideSwiper.slideToLoop(index1 + this.fuzzyLength[0]);
        } else {
          insideSwiper.slideToLoop(index1);
        }
      }
      if (index2 >= 0) {
        // 判断是否处于内涂双击状态
        // console.log(this.insideDbStatus);
        if (this.insideDbStatus === 1) {
          this.$emit("insideDb");
        }
        // 判断是否处于模糊搜索
        if (this.isFuzzy) {
          outsideSwiper.slideToLoop(index2 + this.fuzzyLength[1]);
        } else {
          if (this.recommendData[0] <= index2) {
            outsideSwiper.slideToLoop(index2 + this.recommendData[1]);
          } else {
            outsideSwiper.slideToLoop(index2);
          }
        }
      }
    },
    changeColorOutside(index) {
      let _this = this
      let insideData = this.insideColor[index];
      // 保存外涂编号
      this.$emit("changeColorIndex", "outside", index);
      let outsideData = this.outsideColor[index];
      // 改变模型外涂颜色
      if (this.searchStatus) {
        console.log('3333')
        _this.$emit("outsideDb", 'search');

        this.searchStatus = false
      } else {
        if (outsideData.Material) {
          
          if( parseInt(this.getUrlKey("dbclick"))==2){
            SetColor(outsideData, "outside", "dbclick", "", this.currentProduct);
        }else{
          SetColor(outsideData, "outside", "click", "", this.currentProduct);
        }
          _this.$emit('addLishi')
        }
      }

      if (index >= this.outsideColor.length) {
        _this.$emit("getMoreData", 1, this.keywords);
      }
      // 改变外涂文字描述
      this.sendLanguage("outside", outsideData);
      this.$store.commit("setCurrentSide", "outside");

      if (index >= this.outsideColor.length - 10) {
        _this.$emit("getMoreData", 1, this.keywords);
      }
    },
    screenshot() {
      this.$emit("sendImg");
    },
    showManifier() {
      this.$emit("getManifier", true);
    },
    objToArr(obj) {
      let insideArr = [];
      for (var i in obj) {
        var arr = [];
        arr["text-CN"] = obj[i]["text-CN"];
        arr["text-EN"] = obj[i]["text-EN"];
        arr["keyword"] = obj[i]["keyword"];
        arr["MaterialId"] = obj[i]["MaterialId"];
        arr["Color"] = [obj[i]["Color"][0]];
        arr["id"] = i;
        insideArr.push(arr);
      }
      return insideArr;
    },
    indexOf1(arr, item) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].indexOf(item) >= 0) {
          return i;
        }
      }
      return -1;
    },
  },
};
</script>

<style lang="less"  scoped>
.tabbaroption {
  position: absolute;
  bottom: 30px;
  padding-bottom: 10px;
  height: auto;
  width: 100%;
  margin: 0;
  pointer-events: all;
  box-sizing: border-box;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-image: linear-gradient(#0000000f, #ffffff14);
  /*overflow: hidden;*/
  animation: upcolorSelect 1s;
}

@keyframes upcolorSelect {
  0% {
    bottom: -160px;
  }

  100% {
    bottom: 30px;
  }
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.5);
  background-image: linear-gradient(#0000000f, #ffffff14); */
  background: url(../assets/options/mask.png);
  /* filter: blur(1px); */
  opacity: 0.75;
}

.right-icons {
  position: relative;
  right: 20px;
  top: -50px;
  text-align: right;
}

.tabbaroption hr {
  margin: 0px;
  width: calc(100% - 42px);
  height: 1px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  box-sizing: border-box;
  border: none;
}

.topbox {
  position: relative;
  top: 0;
  height: auto;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  .optionsLeft {
    display: flex;
  }
}

.inconect {
  position: relative;
  top: 0px;
  // left: 2%;
  height: 36px;
  width: 16px;
  pointer-events: all;
  cursor: pointer;
  padding-right: 20px;
}

.inconect img {
  // margin-left: 6px;
  height: 16px;
  width: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.hot {
  position: relative;
  top: 0px;
  // left: 2%;
  height: 36px;
  width: 16px;
  pointer-events: all;
  cursor: pointer;

  img {
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

.selector {
  position: relative;
  border: none;
  top: 0px;
  width: 100%;
  height: auto;
  margin: 3px 0px 22px;
  user-select: none;
}

.direction {
  background: transparent;
  width: auto;
  height: 100%;
  align-items: center;
  display: flex;
  /* height: 32px; */
  // position: relative;
  /* top: 50%; */
  /* transform: translateY(-50%);*/
  cursor: pointer;
}

.direction>img {
  height: 15px;
  width: auto;
}

.end {
  opacity: 0.5;
}

.selected {
  padding: 0px;
  width: 86%;
  position: relative;
  overflow: hidden;
}

// .litseletor {
//   animation: showColors 0.8s 1 forwards;
// }
.litseletor1Animation {
  animation: showColors 0.8s 1 forwards;
}

.litseletor,
.litseletor1 {
  width: 100%;
  background-color: transparent;
  list-style-type: none;
  display: flex;
  padding: 0px;
  margin: 0px;
  padding: 8.5px 0px;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  left: 0%;

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    width: 100%;
    border-radius: 50%;
    cursor: pointer;
    padding: 0 5.5%;
    position: relative;
    box-sizing: border-box;

    .gapline {
      position: absolute;
      width: 1px;
      height: 100%;
      background-color: #fff;
      left: 100%;
    }
  }
}

.slide {
  width: 100%;
  height: 58px;
  // position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.end {
  opacity: 0.2;
}

.unend {
  opacity: 1;
}

@keyframes showColors {
  from {
    left: 100%;
  }

  to {
    left: 0;
  }
}
</style>

<style lang="less"  >
.swiperInside,
.swiperOutside {
  width: 82%;
  height: 100%;

  .swiper-container {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .swiper-slide {
      text-align: center;
      text-align: -webkit-center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .swiper-slide-active .activeColorInside,
    .swiper-slide-active .activeColorOutside {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 4px solid transparent;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border-color: rgba(0, 0, 0, 0.2);
    }

    .gapline {
      position: absolute;
      width: 1px;
      height: 50%;
      background-color: #fff;
      left: 100%;
    }
  }
}

.slide {
  position: relative;

  .swiper-scrollbar1,
  .swiper-scrollbar2 {
    height: 2px;
    width: 82%;
    position: absolute;
    left: 50%;
    bottom: 5%;
    transform: translateX(-50%);
  }
}

@media (min-width: 768px) {
  .selector {
    margin: 10px 0px 20px;
  }

  .slide {
    height: 60px;
  }

  .colorSelected {
    margin: 4px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;

    .activeBgc {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 5px solid transparent;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }
}

@media (max-width: 768px) {
  .colorSelected {
    margin: 4px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;

    .activeBgc {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 4px solid transparent;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }
}

.colorSelected img {
  height: 100%;
  width: auto;
  /* border:1px solid red; */
  pointer-events: none;
  border-radius: 50%;
  box-shadow: 2px 2px 8px #00000020;
}

.swiper-wrapper {
  animation: swiperAnime 1s ease;
}

@keyframes swiperAnime {
  0% {
    left: 100%;
  }

  100% {
    left: 0%;
  }
}

.colorLine {
  margin-left: auto;
  width: 200px;
  height: 7px;
  border-radius: 5px;
  background: linear-gradient(90.02deg, #FFFFFF -0.18%, #000000 9.68%, #FF0E0E 18.12%, #FF5757 25.99%, #FEE043 35.43%, #58FF1E 45.48%, #1FFFBC 54.61%, #00FFD1 65.17%, #231FED 77.76%, #B90BF7 89.33%, #F70B98 99.99%);

}



input[type='range'] {
  -webkit-appearance: none;

}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  margin-top: -20px;
  /*使滑块超出轨道部分的偏移量相等*/
  background: #ffffff;
  border-radius: 50%;
  /*外观设置为圆形*/
  border: solid 0.125em rgba(205, 224, 230, 0.5);
  /*设置边框*/
  box-shadow: 0 0.125em 0.125em #eff1f1;
  /*添加底部阴影*/
}
</style>
<style></style>