var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.inAnimation ? 'Anim pointselect' : 'pointselect',on:{"animationend":_vm.changeAnime,"click":function($event){_vm.showColorList = false}}},[_c('div',{staticClass:"bigbox"},[_c('div',{staticClass:"tabbaroption"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dbclickStatus),expression:"dbclickStatus"}],staticClass:"mask"}),_c('div',{staticClass:"topbox"},[_c('div',{staticClass:"optionsLeft"},[_c('div',{staticClass:"inconect",attrs:{"id":"SendImg"},on:{"click":_vm.screenshot}},[_c('img',{attrs:{"src":require("../assets/options/shoucang.png"),"alt":""},on:{"click":function($event){return _vm.scClick()}}})]),_c('div',{staticClass:"hot",staticStyle:{"margin-right":"20px"},on:{"click":function($event){return _vm.tabClick('icon-04')}}},[_c('img',{attrs:{"src":require("../assets/tabs1.png"),"alt":""}})]),_c('div',{staticClass:"hot",on:{"click":function($event){return _vm.tabClick('icon-0')}}},[_c('img',{attrs:{"src":require("../assets/tabs2d.png"),"alt":""}})])]),_c('div',{staticClass:"search"},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:((_vm.currentInsidePoint == 0 || _vm.currentInsidePoint == 1) && _vm.showColorList),expression:"(currentInsidePoint == 0 || currentInsidePoint == 1) && showColorList"}],class:`sprinkleColor1`,on:{"click":function($event){$event.stopPropagation();}}},[_vm._l((_vm.sprinkleColorList1),function(item1,index1){return _c('li',{key:index1,class:_vm.currentColor1 === item1
                          ? 'sprinkleColorLi activeSprinkleColor'
                          : 'sprinkleColorLi',style:({
                        background: item1,
                      }),on:{"click":function($event){return _vm.changeSprinkleColor(_vm.currentInsidePoint, item1)}}})})],2)])]),_c('hr'),_c('div',{class:_vm.inAnimation ? 'selectorAnime selector' : 'selector'},[_c('div',{staticClass:"slide"},[_c('div',{staticClass:"selected"},[_c('ul',{staticClass:"litseletor"},_vm._l((_vm.insideColor),function(inside_colors,index){return _c('li',{key:index,class:_vm.inAnimation ? 'animeRotateLi' : '',style:({
                  background:
                    _vm.currentInsidePoint == index ? 'rgb(0 0 0 / 10%)' : '',
                  opacity:
                    _vm.outsidePointDbStatus == 1 && _vm.dbIndex != 'point_' + index
                      ? 0.2
                      : 1,
                }),attrs:{"id":index},on:{"dblclick":function($event){return _vm.changeDbStatus('inside', index)},"click":function($event){$event.stopPropagation();return _vm.icsClick(index)}}},[_c('div',{staticClass:"color-selected",style:({
                    background: _vm.currentInside.Color[0],
                  })},[_c('ul',{ref:`pointBgc${index + 1}`,refInFor:true,class:`pointBgc${index + 1} pointBgc`})])])}),0)])]),_c('div',{staticClass:"slide"},[_c('div',{staticClass:"selected"},[_c('ul',{staticClass:"litseletor"},_vm._l((_vm.outsideColor),function(outside_colors,index){return _c('li',{key:index,class:_vm.inAnimation ? 'animeRotateLi' : '',style:({
                  background:
                    _vm.currentOutsidePoint == index ? 'rgb(0 0 0 / 10%)' : '',
                  opacity:
                    _vm.insidePointDbStatus == 1 && _vm.dbIndex != 'light_' + index
                      ? 0.2
                      : 1,
                }),attrs:{"id":'SimLight_' + index},on:{"click":function($event){return _vm.ocsClick(index)},"dblclick":function($event){return _vm.changeDbStatus('outside', index)}}},[_c('div',{staticClass:"color-selected",style:({
                    background: _vm.currentOutside.Color[0],
                  })},[_c('img',{staticClass:"ocs-img",attrs:{"draggable":"false","src":require(`../assets/setpoint/Mask_SimLight_${index}.png`),"alt":""}})])])}),0)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }