<template>
    <div class="user-view" >
 

      

        <div class="info-list">
            <div class="  flex list-item " >
                <div>
                    <img :src="userInfo.avatar" width="64px" height="64px" @click="fileClick">
                    <input type="file" accept="image/*" ref="avatarFile" style="display: none;" @change="doUpdateAvatar">
                </div>
                <div class="flex-end"  @click="fileClick" style="padding-top: 20px;"><span style="font-size: 12px; color: #8D8D8D;">修改头像</span><span style="vertical-align: middle; margin-left: 10px;"><img src="../assets/user/right.png" style="width: 6px; height: 10px;" alt=""></span></div>
            </div>
            
        </div>

        <div class="info-list">
            <div class="  flex list-item " >
                <div>{{userInfo.nikeName}}</div>
                <div class="flex-end" @click="toPage('/editNickName?nikename='+userInfo.nikeName)" ><span style="font-size: 12px; color: #8D8D8D;">修改昵称</span><span style="vertical-align: middle; margin-left: 10px;"><img src="../assets/user/right.png" style="width: 6px; height: 10px;" alt=""></span></div>
            </div>
            
        </div>
 

        <div class="footer">
            <div class="login-btn" @click="logout" >

            退出
            </div>
        </div>
        
    </div>
  </template>
  
  <script>
  //import func from 'vue-editor-bridge';
  export default {
    name: "Member",
    data() {
      return {
        userInfo:null,
        imageUrl:''
      };
    },
    created(){
        this.getUserInfo()
    },
    methods:{
       
        toPage(url){
            this.$router.push(url)
        },

        logout(){
            localStorage.removeItem('token'),
            localStorage.removeItem('userinfo')
            this.$layer.msg("用户已退出")
            setTimeout(() => {
                this.$router.push("/?guige=3")  
            }, 1000);
           
        },
        getUserInfo(){
            ///jeecg-boot/users/api/get/user
            let token = localStorage.getItem('token')
            let _this = this;
            this.$ajax({
                method: "post",
                url:  _this.$global.serverSrc+"/jeecg-boot/users/api/get/user",
                header:{"X-Access-Token":token}
                }).then((res) =>{
                    console.log(res)
                    if(res.data.code==401){
                        this.$router.push("/login")
                    }
                    if(res.data.code==200){
                       _this.userInfo = res.data.result 
                    }
                })

        },
        fileClick(){
            console.log('123')
            this.$refs.avatarFile.click()
        },
        doUpdateAvatar(e){
            let _this = this
            let file = e.target.files[0];
        let param = new FormData(); //创建form对象
        param.append('file',file);//通过append向form对象添加数据
        console.log(param.get('file')); //FormData私有类对象，访问不到，可以通过get判断值是否传进去
        let config = {
          headers:{'Content-Type':'multipart/form-data','X-Access-Token':localStorage.getItem('token')} //这里是重点，需要和后台沟通好请求头，Content-Type不一定是这个值
        };
        this.$ajax({
                method: "post",
                url:_this.$global.serverSrc+"/jeecg-boot/api/common/upload",
                config,
                data:param
                }).then((res) =>{
                    _this.imageUrl = _this.$global.serverSrc+"/jeecg-boot/"+res.data.message

                    let token = localStorage.getItem('token')
                 //   let _this = this;
                    let data = {avatar:_this.imageUrl}
                    this.$ajax({
                        method: "post",
                        url:  _this.$global.serverSrc+"/jeecg-boot/users/api/update/user",
                        header:{"X-Access-Token":token},
                        data,
                        }).then((res) =>{
                            console.log(res)
                            if(res.data.code==200){
                                _this.$layer.msg("编辑成功")

                               _this.getUserInfo()
                            }
                        })
                })

            }  
    }
   
  };
  </script>
  
  <style scoped>
   .user-view{
       width: 100%;
       height:100%;
       background-color: #f0f0f0;
       position:absolute;
      z-index: 9;
      overflow: scroll;
      pointer-events: all;
   }
   .page-header{
      padding-top: 30px; 
      text-align:center;
       width: 100%;
       
      
   }
   .login-text{
    font-size: 12px;
    line-height: 17px;
    color: #8d8d8d;
    margin-top: 20px;
 
   }
   .flex{
    display: flex;
   }
   .tabs{
    width: 90%;
    margin-top: 20px;
    margin:30px auto;
   }
   .tabs .tabs-item{
    width: 46%;
    margin: 0 2%;
    border: 1px solid;
 

    border-image-source: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
    border:1px #fff solid;
    background-image:linear-gradient(180.21deg, rgba(255, 255, 255, 0.2) -9.27%, rgba(255, 255, 255, 0.6) 99.82%) ;
    border-radius: 14px;

    text-align: center;
    height: 64px;
    line-height: 64px;
    color: #3B3B3B;
    font-size: 14px;

   }

   .info-list{
    width: 88%;
    margin-left: 6%;
    margin-top: 20px;
    border-image-source: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.4) 100%);
    border:2px #fff solid;
    border-radius: 14px;
    background-image:linear-gradient(180.21deg, rgba(255, 255, 255, 0.2) -9.27%, rgba(255, 255, 255, 0.6) 99.82%) ;
    

   }
   .flex-end{
    margin-left: auto;
   }
   .list-item{
    margin: 20px;
   }
   .border-b{
    padding-bottom: 20px;
    border-bottom: 1px #fff solid;
   }

   .login-btn{
            width: 315px;
            border-radius: 6px;
            margin: auto;
            background-color: #335D9E;
            color: #ffffff;
            text-align: center;
            font-size: 14px;
            vertical-align: middle;
            line-height: 20px;
            padding: 12px;
        }
        .footer{
            width: 100%;
            
            position: fixed;
            bottom: 80px;
        }
 
        input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
    }
    
 
  </style>